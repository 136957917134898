import React from 'react';

import { Tag, Timeline, Card, Steps } from 'antd';

import './request-timeline.scss';

import { ClockCircleOutlined, CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import RequestInfo from './../../components/request-info/request-info';

const { Step } = Steps;

export default function RequestTimeline({ request = {}, booking = {} }) {
    let insurance = {};

    if (request.insurance && request.insurance.status !== 'pending') {
        insurance = request.insurance;
    } else if (booking.insurance && booking.insurance.status === 'Data Collected') {
        insurance = booking.insurance;
    }

    return (
        <>
            <div className="request-status vehicle-card card">
                <Timeline>
                    <Timeline.Item
                        dot={
                            request.allocation && request.allocation.status === 'Allocated' ? (
                                <CheckCircleOutlined style={{ fontSize: '16px' }} />
                            ) : (
                                <ClockCircleOutlined style={{ fontSize: '16px' }} />
                            )
                        }
                        color={request.allocation && request.allocation.status === 'Allocated' ? 'green' : 'gray'}
                    >
                        Allocation
                        <p>{request.allocation.status}</p>
                        {/* <p>{request.allocation.allocated_at}</p> */}
                    </Timeline.Item>

                    <Timeline.Item
                        color={request.finance.process === 'complete' ? 'green' : 'wait'}
                        dot={
                            request.finance.process === 'complete' ? (
                                <CheckCircleOutlined style={{ fontSize: '16px' }} />
                            ) : (
                                <ExclamationCircleOutlined />
                            )
                        }
                    >
                        Finance
                        <p>{request.finance.status || 'Pending'}</p>
                    </Timeline.Item>

                    <Timeline.Item
                        dot={
                            request.actual_billing && request.actual_billing.status === 'Invoiced' ? (
                                <CheckCircleOutlined style={{ fontSize: '16px' }} />
                            ) : (
                                <ExclamationCircleOutlined />
                            )
                        }
                        color={request.actual_billing && request.actual_billing.status === 'Invoiced' ? 'green' : 'wait'}
                    >
                        Retail
                        <p>{(request.actual_billing && request.actual_billing.status) || 'Pending'}</p>
                    </Timeline.Item>

                    <Timeline.Item
                        dot={insurance.process === 'complete' ? <CheckCircleOutlined style={{ fontSize: '16px' }} /> : <ExclamationCircleOutlined />}
                        color={insurance.process === 'complete' ? 'green' : 'wait'}
                    >
                        Insurance
                        <p>{insurance.status || 'Pending'}</p>
                    </Timeline.Item>

                    {/* If Rto has started show the rto block */}
                    {request.rto ? (
                        <Timeline.Item
                            dot={
                                request.rto.process === 'complete' ? (
                                    <CheckCircleOutlined style={{ fontSize: '16px' }} />
                                ) : (
                                    <ExclamationCircleOutlined />
                                )
                            }
                            color={request.rto.process === 'complete' ? 'green' : 'wait'}
                        >
                            RTO
                            <p>{request.rto.status || 'Pending'}</p>
                        </Timeline.Item>
                    ) : null}

                    <Timeline.Item
                        dot={
                            request.accessories.process === 'complete' ? (
                                <CheckCircleOutlined style={{ fontSize: '16px' }} />
                            ) : (
                                <ExclamationCircleOutlined />
                            )
                        }
                        color={request.accessories.process === 'complete' ? 'green' : 'wait'}
                    >
                        Accessories
                        <p>{request.accessories.status || 'Pending'}</p>
                    </Timeline.Item>

                    {request.warranty ? (
                        <Timeline.Item
                            dot={
                                request.warranty.status === 'Punched' ? (
                                    <CheckCircleOutlined style={{ fontSize: '16px' }} />
                                ) : (
                                    <ExclamationCircleOutlined />
                                )
                            }
                            color={request.warranty.status === 'Punched' ? 'green' : 'wait'}
                        >
                            Extended Warranty
                            <p>{request.warranty.status || 'pending'}</p>
                        </Timeline.Item>
                    ) : null}

                    {request.actual_delivery ? (
                        <Timeline.Item
                            dot={
                                request.actual_delivery && request.actual_delivery.status === 'Delivery Completed' ? (
                                    <CheckCircleOutlined style={{ fontSize: '16px' }} />
                                ) : (
                                    <ExclamationCircleOutlined />
                                )
                            }
                            color={request.actual_delivery && request.actual_delivery.status === 'Delivery Completed' ? 'green' : 'wait'}
                        >
                            Delivery
                            <p>{(request.actual_delivery && request.actual_delivery.status) || 'Delivery Pending'}</p>
                        </Timeline.Item>
                    ) : null}
                </Timeline>

                <RequestInfo booking={booking} request={request} />
            </div>
        </>
    );
}
