/**
 * Generic List accepts an array of fields to update any resource
 */

import React, { useState, useRef, useEffect, useContext } from 'react';

import { Link } from 'react-router-dom';

import { Typography, Button, Table, Space, Popconfirm, message, DatePicker } from 'antd';

import { withRouter } from 'react-router-dom';

import { ExportReactCSV } from './ExportReactCSV';

import './generic-list.scss';

import FirebaseUtils from './../../../../../utils/firebase.utils';
import { GetData } from './../../../../../utils/http.utils';

import { ReloadOutlined, DeleteOutlined, CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';

import useDeviceDetect from './../../../../../hooks/device-detect';

import PlaceHolder from './../../../../../components/ui_elements/PlaceHolder';

import { GlobalContext } from './../../../../../Store';

import moment from 'moment';

const { RangePicker } = DatePicker;

const { Title } = Typography;

/**
 * Generic List
 *
 * @param {*} param0
 */
function GenericList({
    match,
    collection = 'list',
    list,
    schema = { custom_actions: [] },
    model = schema.model,
    columns = schema.columns || [],
    isDirect = schema.isDirect,
    disableUpload = schema.disableUpload,
    disableAdd = schema.disableAdd,
    disableSelect = schema.disableSelect,
    queries = schema.queries || [],
    actions = schema.actions || [],
    dateField,
    path,
}) {

    schema.dateField = dateField;

    const [range, setRange] = useState([moment().startOf('month'), moment().endOf('day')]);

    const [records, setRecords] = useState({ [model]: [] });

    const [expRecord, setExptRecords] = useState();

    const { isMobile } = useDeviceDetect();

    const { user, disableDisplayPhoneNumber } = useContext(GlobalContext);

    const { params } = match;

    const { city } = params;

    // const city = user.locations[0];

    const [loading, setLoading] = useState(false);

    const [location, setLocation] = useState(user.locations[0]);

    const [view, setView] = useState(isMobile || !!schema.Card);

    const [selected, setSelected] = useState([]);

    const [addQueries, setAddQueries] = useState(queries);

    const [disableNext, setDisableNext] = useState(false);


    let isRequest = model === ('requests') || model === ('bookings')

    {/*disable PhoneNumber for kec and tl in Bookings and Requests*/ }
    if (disableDisplayPhoneNumber) {
        columns = columns.filter(column => column.caption !== 'Contact Number' && column.caption !== 'Phone');
    }

    const cols = [
        ...[
            {
                title: '#',
                dataIndex: 'index',
                render: (value, item, index) => (options.page - 1) * options.pageSize + index + 1,
            },
        ],
        ...columns.map((entry) => {
            if (entry.sort) {
                return {
                    render: (record) => {
                        if (entry.render) {
                            return entry.render(record);
                        } else {
                            return entry.field.split('.').reduce((acc, part) => acc && acc[part], record);
                        }
                    },
                    title: entry.caption,
                    key: entry.field,
                    sorter: (a, b) => entry.sort(a, b),
                    sortDirections: ['ascend', 'descend', 'ascend'],
                };
            } else {
                return {
                    render: (record) => {
                        if (entry.render) {
                            return entry.render(record);
                            // return entry.field.split('.').reduce((acc, part) => acc && acc[part], record);
                        } else {
                            return entry.field.split('.').reduce((acc, part) => acc && acc[part], record);
                        }
                        // return record[entry.field]
                    },
                    title: entry.caption,
                    key: entry.field,
                };
            }
        }),
        ...[
            {
                title: '',
                key: 'action',
                render: (text, record) => {

                    let detail = model.slice(0, model.length - 1);

                    let path = `/${city}/${model}/${text.id}`;

                    if (isRequest) {

                        path = `/${model}/${text.id}`

                    }

                    return (
                        <Space size="middle">

                            {!schema.hideView && !actions.length ? <Link to={path}>View</Link> : null}
                            {/* {!schema.hideView && !actions.length ? <Link to={`/${city}/${model}/${text.id}`}>View</Link> : null} */}

                            {actions.map((action) => (
                                <Link to={action.url(record)}>{action.caption}</Link>
                            ))}
                        </Space>
                    );
                },
            },
        ],
    ];

    const [options, setOptions] = useState({
        page: 1,
        current: 1,
        pageSize: 20,
    });

    const [head, setHead] = useState({});

    const [bucket, setBucket] = useState([]);

    useEffect(() => {
        // Update
        let update = [...queries];

        if (schema.dateField) {
            // Update
            update = [
                ...queries,
                {
                    field: schema.dateField,
                    operator: '>=',
                    value: moment(range[0]).valueOf(),
                },
                {
                    field: schema.dateField,
                    operator: '<=',
                    value: moment(range[1]).valueOf(),
                },
            ];

            setAddQueries(update)
        }

        loadData(update);
    }, []);


    const getRecords = (schema, queries) => {
        // console.log(schema);
        switch (schema.node) {
            case 'nest':
                return getNodeData(schema, queries);
            default:
                return getFirebaseData({ schema, queries });
        }
    };

    function getFirebaseData({ schema, queries }) {
        let updatedCity;

        if (schema.isDirect) {
            if (city === 'common') {
                updatedCity = null;
            } else {
                updatedCity = city;
            }

            FirebaseUtils.getRecords(updatedCity, queries, model).then((result) => {
                var data = result;

                if (schema.filterResults && typeof schema.filterResults === 'function') {
                    data[model] = result[model].filter(schema.filterResults);
                }

                // For Sorting
                let content = data;

                if (schema.sort) {
                    content[model] = schema.sort(content[model]);
                }
                if (model === 'requests') {
                    content[model] = content[model].filter((data) => data.status !== 'removed');
                }

                setRecords(content);

                setLoading(false);

                var exporData = getExportData(result[model], columns);

                setExptRecords(exporData);
            });
        } else {

            let params = {
                pageSize: options.pageSize,
            };

            var config;

            config = {
                limit: options.pageSize,
                end: options.end,
                orderBy: schema.sort,
                direction: 'desc',
            };

            params.start = options.end;

            FirebaseUtils.getOrderRecords(city, queries, model, config, schema).then((content) => {

                // Disable nextbooking button
                if (content[model].length === options.pageSize) {
                    setDisableNext(false)
                } else {
                    setDisableNext(true)
                }

                setRecords(content);

                var exporData = getExportData(content[model], columns);

                setExptRecords(exporData);

                setLoading(false);

                // Update the bucket array, which seems to store the pagination state.
                // Modify options and head objects with the new pagination values:

                bucket.push(content.start);

                setBucket(bucket);

                if (content.end) {
                    options.end = content.end;

                    head.end = content.end;

                    setOptions({ ...options });

                    setHead({ ...head });
                }

                if (content.start) {
                    options.start = content.start;

                    head.start = content.start;

                    setOptions({ ...options });

                    setHead({ ...head });
                }
            });

        }

    }

    function getExportData(data, columns) {
        //
        return data.map((record, index) => {


            var content = {
                index: index + 1,
            };


            columns.forEach((column) => {
                content[column.caption] = getValueByPath(record, column.field);
            });

            return content;
        });
    }

    /**
         * Load next page of data. Pagination for firebase data. 20 is kept as limit
         *
         */

    function getNextBookings(location, options = {}) {
        setLoading(true);

        let params = {
            pageSize: options.pageSize,
        };

        var config;

        config = {
            limit: options.pageSize,
            end: options.end,
            orderBy: schema.sort,
            direction: 'desc',
        };

        params.start = options.end;

        FirebaseUtils.getOrderRecords(location, addQueries, model, config, schema).then((content) => {

            // Disable nextbooking button
            if (content[model].length === options.pageSize) {
                setDisableNext(false)
            } else {
                setDisableNext(true)
            }

            setRecords(content);

            setLoading(false);

            bucket.push(content.start);

            setBucket(bucket);

            if (content.end) {
                options.end = content.end;

                head.end = content.end;

                setOptions({ ...options });

                setHead({ ...head });
            }

            if (content.start) {
                options.start = content.start;

                head.start = content.start;

                setOptions({ ...options });

                setHead({ ...head });
            }
        });
    }

    /***
     *
     * Load previous page datas
     *
     *
     */

    function getPrevBookings(location, options = {}) {

        setLoading(true);

        let params = {
            pageSize: options.pageSize,
        };

        bucket.pop();

        params.start = bucket[bucket.length - 1];


        var config;

        config = {
            limit: options.pageSize,
            startAt: bucket[bucket.length - 1],
            orderBy: schema.sort,
            direction: 'desc',
        };

        FirebaseUtils.getOrderRecords(location, addQueries, model, config, schema).then((content) => {

            setRecords(content);

            // Disable nextbooking button
            if (content[model].length === options.pageSize) {
                setDisableNext(false)
            } else {
                setDisableNext(true)
            }

            setLoading(false);

            if (content.end) {
                options.end = content.end;

                head.end = content.end;

                setOptions({ ...options });

                setHead({ ...head });
            }

            if (content.start) {
                options.start = content.start;

                head.start = content.start;

                setOptions({ ...options });

                setHead({ ...head });
            }
        });
    }



    // Function to get the value based on the column.field
    function getValueByPath(obj, path) {
        const keys = path.split('.');

        let value = obj;

        for (const key of keys) {
            value = value[key];
            if (value === undefined) {

                // return undefined if any key is not found
                return undefined;
            }
        }
        return value;
    }

    function getNodeData(schema) {
        GetData({ url: schema.endPoint + schema.queryParams }).then((result) => {
            console.log(result);

            setLoading(false);

            setRecords({ [model]: result });
        });
    }

    const loadData = (queries) => {
        setLoading(true);

        setSelected([]);

        getRecords(schema, queries);
    };

    function refresh() {
        loadData(queries);
    }

    const onSelectChange = (keys, rows) => {
        console.log(keys, rows);
        setSelected(keys);
    };

    const rowSelection = {
        selected,
        onChange: onSelectChange,
    };

    function deleteSelected() {
        setLoading(true);

        Promise.all(
            selected.map((entry) => {
                var count = 0;

                if (isDirect) {
                    return FirebaseUtils.deleteRecord(model, entry).then(() => {
                        count++;
                        console.log(count);
                    });
                } else {
                    return FirebaseUtils.deleteListing(model, entry).then(() => {
                        count++;
                        console.log(count);
                    });
                }
            })
        ).then(() => {
            message.success('Selected records has been deleted');

            setLoading(false);

            setSelected([]);

            loadData();
        });
    }

    function updateFilter(range) {
        let update = [
            ...queries,
            {
                field: schema.dateField,
                operator: '>=',
                value: moment(range[0]).valueOf(),
            },
            {
                field: schema.dateField,
                operator: '<=',
                value: moment(range[1]).valueOf(),
            },
        ];

        setRange(range);

        loadData(update);
    }

    return (
        <section className="generic-list">
            {/* Table Header */}
            <div className="table-header">
                <div className="table-title">
                    <Title level={4}>
                        {city} {model}
                    </Title>
                </div>

                <div className="table-actions">
                    {/* Table Filters */}
                    <div className="table-filters">
                        <Space direction="vertical" size={12}>
                            {schema.dateField ? (
                                <>
                                    <RangePicker
                                        allowClear={false}
                                        inputReadOnly
                                        format={'DD/MM/YYYY'}
                                        value={range}
                                        onChange={updateFilter}
                                        ranges={{
                                            Today: [moment(), moment()],

                                            Yesterday: [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],

                                            'This Week': [moment().startOf('week'), moment().endOf('week')],

                                            'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],

                                            'This Month': [moment().startOf('month'), moment().endOf('month')],

                                            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                                        }}
                                    />
                                </>
                            ) : null}
                        </Space>
                    </div>
                    {/* Table Filters Ends */}

                    <div className="button-container">
                        {selected.length ? (
                            <>
                                {selected.length} selected .
                                <Popconfirm
                                    title="Are you sure you want to delete the selected rows ? "
                                    onConfirm={() => deleteSelected()}
                                    onCancel={() => { }}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button size="small">
                                        <DeleteOutlined />
                                    </Button>
                                </Popconfirm>
                                {schema.custom_actions.map((action) => {
                                    return (
                                        <Popconfirm
                                            title="Are you sure you want to perform the action on selected rows ? "
                                            onConfirm={() => action.onClick(selected, city, refresh)}
                                            onCancel={() => { }}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button size="small">{action.caption}</Button>
                                        </Popconfirm>
                                    );
                                })}
                            </>
                        ) : null}

                        <Space size="small">
                            {/* <Switch
                                 defaultChecked
                                 onChange={changeView}
                                 checked={view}
                                 checkedChildren={<OrderedListOutlined />}
                                 unCheckedChildren={<PicCenterOutlined />}
                             /> */}

                            <Button onClick={refresh} type="secondary" size={'small'}>
                                <ReloadOutlined />
                            </Button>

                            {/* <Button onClick={exportTableLocal} type="secondary" size={'small'}>
                                 Download
                             </Button> */}

                            {/*table data export to csc component*/}
                            {expRecord && <ExportReactCSV csvData={expRecord} />}

                            {/* <Button disabled={options.page <= 1} size="small" onClick={() => { }}>
                                 <CaretLeftOutlined />
                             </Button>
 
                             <Button size="small" onClick={() => { }}>
 
                                 <CaretRightOutlined />
 
                             </Button> */}

                            <Button
                                disabled={options.page <= 1}
                                size="small"
                                onClick={() => {
                                    options.page--;

                                    setOptions(options);

                                    getPrevBookings(city, options);

                                    // getBookings(location, options, true);
                                }}
                            >
                                <CaretLeftOutlined />
                            </Button>

                            <Button
                                disabled={disableNext}
                                size="small"
                                onClick={() => {
                                    getNextBookings(city, options);

                                    options.page++;

                                    setOptions(options);
                                }}
                            >
                                <CaretRightOutlined />
                            </Button>

                            {
                                disableUpload
                                    ?
                                    null
                                    :
                                    model === "items" ? (
                                        <Button type="dashed">
                                            <Link to={`/${city}/${model}/custom/upload/file`}>
                                                Upload items
                                            </Link>
                                        </Button>) : (
                                        <Button type="dashed">
                                            <Link to={`/${city}/${model}/upload/file`}>
                                                Upload
                                            </Link>
                                        </Button>
                                    )
                            }

                            {
                                disableAdd
                                    ?
                                    null
                                    :
                                    model === 'items' ?
                                        <Button type="dashed">
                                            <Link to={`/${city}/${model}/create/custom`}>
                                                Add
                                            </Link>
                                        </Button>
                                        :
                                        <Button type="dashed">
                                            <Link to={`/${city}/${model}/create/`}>
                                                Add
                                            </Link>
                                        </Button>
                            }

                        </Space>
                    </div>
                </div>
            </div>

            {/* Table Header Ends */}

            {loading ? (
                <PlaceHolder type="listing" />
            ) : (
                <>
                    <>
                        {!view ? (
                            <Table
                                className="table-exportable"
                                loading={loading}
                                size="small"
                                rowSelection={!disableSelect ? rowSelection : null}
                                // scroll={{ x: true, y: 750 }}

                                // scroll={{ x: true, y: 750 }}
                                rowKey={(record) => record.id}
                                // rowKey={record => record[model + 'Id']}
                                dataSource={records[model]}
                                columns={cols}
                                // onChange={handleTableChange}
                                pagination={false}
                            ></Table>
                        ) : (
                            <CardList schema={schema} url={city} data={records[model]} />
                        )}
                    </>
                </>
            )}
        </section>
    );
}

export default withRouter(GenericList);

function CardList({ schema, data, url, ...props }) {
    return data.map((report, index) => {
        return <schema.Card city={url} booking={report} index={index} key={index} {...report} {...props} />;
    });
}
