import React, { useEffect, useState, useContext } from 'react';

import { Tag, Modal, Button, Popconfirm, message } from 'antd';

import { Link } from 'react-router-dom';

import { GlobalContext } from '../../../../../../Store';

import BlockVehicle from '../../../block-vehicle/block-vehicle';

import FirebaseUtils from '../../../../../../utils/firebase.utils';

import moment from 'moment';

import { CheckCircleOutlined } from '@ant-design/icons';

import { Requests, Vehicles, Cart, StockTransfers, Allocations, StockPoints, Bookings, Settings } from '../../../../../../models/';

import './vehicle-card.scss';

import { Typography } from 'antd';

import { VehicleDisplayCard } from '../../../vehicle-display-card/vehicle-display-card';

import { BookingStatusModal } from '../../../../../accounts/components/booking-status-modal/booking-status-modal';


/**
 * Vehicle Card for All the vehicles
 */
export function VehicleCard({ vehicle, requests, city, callback,access_unblocking }) {
    const [request, setRequest] = useState({ customer: {} });

    const [bookingStatusModal, setBookingStatusModal] = useState(false);

    const { user } = useContext(GlobalContext);

    useEffect(() => {
        if (vehicle.allocated && vehicle.allocated.requestId) {
            //
            Requests.getRecord(vehicle.allocated.requestId).then((result) => {
                setRequest(result);


            });
        }
        // getStockLocation(vehicle)
        // getStockAge(vehicle)
    }, []);

    /**
     * Unallocate the vehicle from the customer
     */
    async function unblock() {
        setBookingStatusModal(true)

        var app = FirebaseUtils.getApp();

        var batch = app.batch();

        var request = requests.filter((item) => item.id === vehicle.allocated.requestId).pop();

        await Vehicles.unblockVehicle(vehicle, request, batch).then(async()=>{
            
        await batch.commit();

        // callback();

        message.success('Vehicle Unblocked');

        })



    }

    let isSenior = ['admin', 'sm', 'rm'].indexOf(user.role) !== -1;

    // For
    let isAllocated = vehicle.allocated && vehicle.allocated.requestId;

    let allowUnblock = false;

    let forBilling = false;

    if (vehicle.allocated && vehicle.allocated.requestId) {
        allowUnblock = true;

        if (request && request.actual_billing && request.actual_billing.status !== 'pending') {
            forBilling = true;
        }
    }


    return (
        <div className="vehicle-card">
            <div className="left">


                {/* <p>
                    {vehicle['Variant']}
                    <span>{` [ ${vehicle['Exterior Color Name']} ] `}</span>
                </p> */}

                <VehicleDisplayCard vinNo={vehicle['Vin Number']} />

                {/* Allocated customr */}
                {isAllocated ? (
                    <div>
                        Allocated to {request && request.customer && request.customer.customerName} at {vehicle.allocated.created_at} .{' '}
                        <Link to={`/requests/${vehicle.allocated.requestId}`}>View Request</Link>
                    </div>
                ) : null}
                {/* Allocated customr Ends */}

            </div>
            <div className="right card-buttons">

                {allowUnblock ? (
                    <div>
                        {isSenior && !forBilling && access_unblocking ? (
                            <Popconfirm
                                title="Are you sure you want to Unblock this Vehicle ? "
                                onConfirm={() => {
                                    unblock();
                                }}
                                onCancel={() => { }}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button size="small" type="secondary">
                                    Unblock
                                </Button>{' '}
                            </Popconfirm>
                        ) : null}
                    </div>
                ) : (
                    <div>
                        {isSenior ? <BlockVehicle vehicle={vehicle} requests={requests} city={city} callback={callback} /> : null}

                        {isAllocated ? (
                            <Tag icon={<CheckCircleOutlined />} color="success">
                                Blocked
                            </Tag>
                        ) : null}
                    </div>
                )}

                {forBilling ? (
                    <>
                        <p>
                            Vehicle is <Tag color="blue">{request.actual_billing.status}</Tag> for billing
                        </p>
                    </>
                ) : null}

                {/* update booking current status value when unblocking the vehicle  */}
                {bookingStatusModal ?
                    (
                        <div>
                            <Modal
                                width={'50%'}
                                destroyOnClose={false}
                                footer={null}
                                title="Booking Status"
                                visible={bookingStatusModal}
                                okText="Okay"
                                onOk={() => {
                                    // setBookingStatusModal(false);
                                }}
                                onCancel={() => {
                                    // setBookingStatusModal(false);
                                }} >

                                <BookingStatusModal id={vehicle.bookingNo} callback={callback} />
                            </Modal>
                        </div>
                    ) : null}
                {/*Ends */}

            </div>
        </div>
    );
}
