/**
 *
 *
 * @param {*} param0
 */

import { useState, useContext, useEffect } from "react";

import { GlobalContext } from "../../Store";

import { Button, Popconfirm, message, Modal } from 'antd'

import { EditOutlined, DeleteOutlined, FilePdfOutlined } from '@ant-design/icons'


import FirebaseUtils from "../../utils/firebase.utils";

import Warranty from "../warranty/warranty";

export default function SummaryDescription({ record, callback }) {
    const [visible, setVisible] = useState(false);

    const { user } = useContext(GlobalContext);

    const { request } = record;

    let isDelivered = false;

    let isSettlementTaken = false

    if (request.delivery) {
        if (['Delivered', 'Delivery Completed'].indexOf(request.delivery.status) !== -1) {
            isDelivered = true;
        }
    }

    // If settlement is taken, make isSettlementTaken true and disable edit option for extended warranty
    if (request && request.actual_delivery && request.actual_delivery && request.actual_delivery.settlementTaken) {
        isSettlementTaken = true
    }

    useEffect(() => {
        return () => { };
    }, []);

    /**
     * Delete the Cart Item
     */
    const deleteEntry = (entry) => {
        if (entry.table === 'bill-details') {
            FirebaseUtils.deleteBillDetailsItem(entry.id).then(() => {
                message.success('Selected cart item has deleted successfully. ');
                callback();
            });

        } else {
            FirebaseUtils.deleteCartItem(entry.id).then(() => {
                message.success('Selected cart item has deleted successfully. ');
                callback();
            });
        }
    };

    return (
        <>
            <div style={{ height: '20px' }}>

                {record.description || record.name}

                {/* {
                record.status ?
                    <Tag color="orange">
                        {record.status}
                    </Tag>
                    :
                    null
            } */}

                {/* Extended Warranty */}
                {['sm', 'admin', 'kec', 'tl'].indexOf(user.role) !== -1 &&
                    record.type === 'Extended Warranty' && ((
                        !record.isSnapshot && !isSettlementTaken &&
                        !isDelivered) || (isSettlementTaken && record.price != 0)) ? (
                    <Button
                        onClick={() => {
                            setVisible(!visible);
                        }}
                        type="dashed"
                        size="small"
                        style={{ marginLeft: '10px', display: 'none', zIndex: '1' }}
                    >
                        <EditOutlined />
                    </Button>
                ) : null}

                {/* Extended Warranty Ends */}
                {/* New Iems added */}
                {['accounts', 'admin'].indexOf(user.role) !== -1 && record.type === 'additional' && !record.isSnapshot && !isDelivered ? (
                    <Popconfirm
                        title="Are you sure you want to delete this item ? "
                        onConfirm={() => deleteEntry(record)}
                        onCancel={() => { }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="dashed" size="small" style={{ marginLeft: '10px' }}>
                            <DeleteOutlined />
                        </Button>
                    </Popconfirm>
                ) : null}
                {/* New Iems added Ends */}
                {record.table && record.table == 'bill-details' ? (
                    <span style={{ marginLeft: '10px' }}>
                        <FilePdfOutlined />
                    </span>
                ) : null}
            </div>

            {/* Modal for updating Extended Warranty */}
            <Modal
                destroyOnClose={true}
                footer={null}
                title="Extended Warranty"
                visible={visible}
                okText="Okay"
                onOk={() => {
                    setVisible(false);
                }}
                onCancel={() => {
                    setVisible(false);
                }}
            >
                <Warranty
                    record={record}
                    callback={() => {
                        setVisible(false);

                        callback();
                    }}
                />
            </Modal>
            {/* Modal for updating Extended Warranty Ends */}
        </>
    );
}