/**
 * The first component of this project that made allocations happen
 *
 * Right now `new` and `remove` are flags to identify the current status of stock
 *
 *
 */

import React, { useEffect, useState, useContext } from 'react';

import XLSX from 'xlsx';

import { Link } from 'react-router-dom';

import { Input, Table, Form, Space, Upload, message, Divider, Card, Typography, Button, Tag, Badge, Select, Checkbox, Modal } from 'antd';

import { GlobalContext } from '../../../../Store';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import { UploadOutlined, SearchOutlined } from '@ant-design/icons';

import { Vehicles, Pricing, Branches, StockPoints, StatusMasters, LookUpTypes, LookUpValues } from './../../../../models';

import './manage-stock.scss';

import Rupee from '../../../../components/rupee/rupee';

import moment from 'moment';

import { ExportReactCSV } from '../../resources/generic/generic-list/ExportReactCSV';

import StatusChange from './../../../../components/status-change/status-change';

import EditableCell from './../../../../components/editable-cell/editable-cell';
import VehicleTracker from '../vehicle-tracker/vehicle-tracker';
import { cityKeys, modelImages } from '../../../global-config';

const { Text, Title } = Typography;

const { Option } = Select;
const { Search } = Input;

var images = modelImages;

var badgeColor = {
    Allocated: 'error',
    'In transit': 'warning',
    'Free Stock': 'success',
};

var statusColor = {
    remove: 'red',
    status: 'orange',
    new: 'green',
};

var cityCode = cityKeys;

export default function ManageStock() {
    const [view, setView] = useState('Normal');

    const [btnloading, setBtnloading] = useState(true);

    const [files, setFiles] = useState([]);

    const { user } = useContext(GlobalContext);

    const [loading, setLoading] = useState(true);

    const [result, setResults] = useState([]);

    const [location, setLocation] = useState(user.locations[0]);

    const [vehicles, setVehicles] = useState({});

    const [stockPoints, setStockPoints] = useState([]);

    const [billedVehicles, setBilledVehicles] = useState({});

    const [stock, setStock] = useState({});

    const [branch, setBranch] = useState({});

    const [price, setPrice] = useState({});

    const [masters, setMasters] = useState([]);

    const [arrayWithoutSellingPrice, setArrayWithoutSellingPrice] = useState({});

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [value, setValue] = useState();

    const [vehicleSpecConfig, setVehicleSpecConfig] = useState([]);

    const [options, setOptions] = useState({
        page: 1,
        current: 1,
        pageSize: 20,
    });

    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            disableExport: true,
            render: (value, item, index) => (options.page - 1) * options.pageSize + index + 1,
        },

        {
            title: 'Vin Number',
            dataIndex: 'Vin Number',
            key: 'Vin Number',
            render: (record) => {
                // console.log(record);

                return <Link to={`/vehicle/${record}`}>{record}</Link>;
            },
            // For Download data
            exportDefinition: (record) => {
                return record['Vin Number'];
            },
        },

        {
            title: 'DealerCode',
            dataIndex: 'dealerCode',
            key: 'dealerCode',
        },

        {
            title: 'Color',
            dataIndex: 'Exterior Color Name',
            key: 'color',
        },

        {
            title: 'Model',
            dataIndex: 'Model',
            key: 'model',
        },

        {
            title: 'Variant',
            dataIndex: 'Variant',
            key: 'variant',
        },

        {
            title: 'PDI',
            render: (record) => {
                let completed = record.pdi && record.pdi.status === 'completed';

                return <Tag color={`${completed ? 'green' : 'orange'}`}>{record.pdi && record.pdi.status}</Tag>;
            },
            key: 'pdi',

            //For download data
            exportDefinition: (record) => {
                return record.pdi && record.pdi.status;
            },
        },

        {
            title: 'Stock Status',
            exportDefinition: (record) => {
                return record.update === 'remove' ? 'Retailed' : record.allocated ? 'Allocated' : 'Free Stock';
            },

            render: (record) => {
                return record.update === 'remove' ? 'Retailed' : record.allocated ? 'Allocated' : 'Free Stock';
            },
            key: 'Stock Status',
        },
        // {
        //     title: 'Stock Location',
        //     dataIndex: 'Stock Location',
        //     key: 'Stock Location',
        // },
        {
            title: 'Yard',
            // width: 200,
            exportDefinition: (vehicle) => {
                let matchingStockPoint = stockPoints.filter((record) => record.id === vehicle.stock_point_id);

                if (matchingStockPoint && matchingStockPoint.length) {
                    return matchingStockPoint[0].name;
                } else {
                    return '';
                }
            },
            dataIndex: 'Yard',
            render: (text, record) => {
                return (
                    <Space size="middle" style={{ minWidth: '180px' }}>
                        <Select
                            value={record.stock_point_id ? record.stock_point_id : null}
                            disabled={true}
                            style={{ width: '180px' }}
                            onChange={(ele) => {
                                handleStatusChange(ele, record, 'dropdown');
                            }}
                        >
                            {record.stock_point_id &&
                                stockPoints.map((ele) => {
                                    return <Option value={ele.id}>{ele.name}</Option>;
                                })}
                        </Select>
                    </Space>
                );
            },
            key: 'Stock ',
            width: 150,
        },

        // {
        //     title: 'Status',
        //     exportDefinition: (record) => {
        //         return record.vehicle_status_name;
        //     },
        //     // width: 200,
        //     // dataIndex: 'status',
        //     render: (vehicle, record) => {
        //         return (
        //             <Space size="middle" style={{ minWidth: '180px' }}>
        //                 <StatusChange
        //                     formValues={{
        //                         status_id: vehicle.vehicle_status,
        //                         reference_number: vehicle.id,
        //                     }}
        //                     referenceNumber={vehicle.id}
        //                     statusId={vehicle.vehicle_status}
        //                     caption={'Vehicle Status'}
        //                     mode="VEHICLESTOCK"
        //                     description={vehicle.vehicle_status_name}
        //                     callback={(status) => {
        //                         updateVehicleStatus(vehicle, status);
        //                     }}
        //                     updateDisabled={true}
        //                 />
        //             </Space>
        //         );
        //     },
        //     key: 'Stock ',
        //     width: 150,
        // },

        {
            width: 300,

            exportDefinition: (record) => {
                return record.remarks;
            },

            title: 'Remarks',
            // dataIndex: 'Remarks',
            key: 'Remarks',
            render: (record) => {
                return (
                    <EditableCell
                        field="remarks"
                        text={record.remarks}
                        onUpdate={(values) => {
                            // console.log(values)

                            record.remarks = values.remarks;

                            updateVehicleField(record, record.remarks, 'remarks');
                        }}
                    />
                );
            },
        },

        {
            title: 'Age',
            // dataIndex: 'Stock Age',
            render: (record) => {
                let curdate = moment().valueOf();
                // let specifieddate = record['KIN Invoice Date'] && moment(record['KIN Invoice Date'], "DD/MM/YYYY").format("DD MM YYYY");

                let difference = curdate - moment(record['KIN Invoice Date'], 'DD/MM/YYYY').valueOf();

                const diffInDays = difference / 86400000;
                // console.log('hhh', record['KIN Invoice Date']);
                // console.log(moment(record['KIN Invoice Date'], "DD/MM/YYYY").format("DD MM YYYY"));
                // console.log(curdate - moment(record['KIN Invoice Date'], "DD/MM/YYYY").valueOf());
                // console.log('diffInDays', diffInDays);
                return diffInDays.toFixed(0);
            },
            key: 'date',
        },

        {
            title: 'KIN Invoice Date',
            dataIndex: 'KIN Invoice Date',
            key: 'KIN Invoice Date',
        },

        {
            title: 'KIN Invoice No',
            dataIndex: 'KIN Invoice No',
            key: 'KIN Invoice No',
        },
        {
            title: 'Vehicle Tracking',
            render: (record) => {
                {
                    /* vehicle's location tracking by tracking number */
                }
                if (record && record.tracking_number) {
                    return (
                        <>
                            <VehicleTracker record={record} />
                        </>
                    );
                }
            },
            key: 'tracking_number',
        },
    ];

    if (user.role === 'accounts' || user.role === 'sm' || user.role === 'edp') {
        columns.push(
            {
                title: 'Stock Value',
                render: (record) => {
                    return record.stock_value && record.stock_value.toFixed(2);
                },
                key: 'stock_value',
            },

            {
                title: 'Stock Value (Incl. Gst)',
                render: (record) => {
                    return record.stock_value_including_gst && record.stock_value_including_gst.toFixed(2);
                },
                key: 'stock_value_including_gst',
            }
        );
    }

    if (user.role === 'accounts') {
        columns.push({
            title: 'Action',
            key: 'action',
            disableExport: true,
            render: (text, record) => {
                return (
                    <Space size="middle">
                        <Link to={`/vehicle/${text['Vin Number']}`}>View</Link>
                    </Space>
                );
            },
        });
    }

    /**
     * Update the vehicle status and description
     *
     * @param {*} status
     */
    async function updateVehicleStatus(vehicle, status) {
        await Vehicles.update(vehicle.id, {
            vehicle_status: status.id,
            vehicle_status_name: status.name,
        });

        message.success('Status Updated');

        getPricing(location, null);
    }

    /**
     * Update the vehicle field
     *
     * @param {*} vehicle
     * @param {*} formValues
     * @param {*} field
     */
    async function updateVehicleField(vehicle, value, field) {
        await Vehicles.update(vehicle.id, {
            [field]: value,
        });

        message.success('Remarks updated');
    }

    useEffect(() => {
        // To get vehicle spec configuration
        getVehicleSpecConfiguration();

        if (user) {
            getPricing(location, null).then(() => {
                // getStock(location);
            });
            //Get all cases that are billed in surge but not in dms
            getBilledVehicles(location);

            getYardDetails(location);

            getStatusMaster();
        }

        // setInterval(() => {

        //     getPricing(location);

        // }, 10000)
    }, [user]);

    /**
     *
     * @param {*} ele
     * @param {*} record
     * @param {*} key
     */

    const getVehicleSpecConfiguration = async () => {
        var query = [
            {
                field: 'name',
                value: 'Vehicle spec configuration',
            },
        ];

        var result = await LookUpTypes.get(query);

        console.log(result['lookup-types']);

        console.log(result['lookup-types'][0]);

        // Get look-up-values according to lookuptype
        console.log(result['lookup-types'][0].id);

        var look_up_query = [
            {
                field: 'lookup_type_id',
                value: result['lookup-types'][0].id,
            },
        ];

        var vehicle_spec_configuration = await LookUpValues.get(look_up_query);

        setVehicleSpecConfig(vehicle_spec_configuration['lookup_values']);
    };

    const handleStatusChange = (ele, record, key) => {
        let values = {
            stock_point_id: ele,
        };

        Vehicles.update(record.id, values);
    };

    //Check if any billed or delivered vehicle has dms_stock new
    function getBilledVehicles() {
        var queries = [
            {
                field: 'dms_stock',
                value: 'new',
            },
            {
                field: 'update',
                operator: 'in',
                value: ['remove', 'bbnd'],
                // value: ['Free Stock', 'In transit', 'Allocated'],
            },
            {
                field: 'dealerCode',
                value: cityCode[location],
            },
        ];

        //checking if any billed vehicle has dms_stock new
        Vehicles.get(queries).then((res) => {
            var arr = [];
            Promise.all(
                res.vehicles.map((i) => {
                    arr[i['Vin Number']] = i;
                })
            );
            setBilledVehicles(arr);
        });
    }

    //Check if any billed vehicle has dms_stock new

    /**
     * Function to load stock points
     *
     * @param {*} loc
     */
    function getYardDetails(location) {
        var queries = [
            {
                field: 'location',
                value: location,
            },
        ];

        StockPoints.get(queries).then((res) => {
            setStockPoints(res['stock_points'] || []);
        });
    }

    /**
     * Load the status Master
     */
    async function getStatusMaster() {
        let queries = [
            {
                field: 'mode',
                value: 'VEHICLESTOCK',
            },
        ];
        const result = await StatusMasters.get(queries);

        setMasters(result.status_masters || []);
    }

    /**
     * Get the pricing
     */
    function getPricing(location, status) {
        setLoading(true);

        return Pricing.get().then(({ pricing }) => {
            return getStock(location, pricing, status);
        });
    }

    /**
     * Get stock Data
     */
    function getStock(location, pricing, status) {
        setLoading(true);

        // when status have value or undefined
        if (status || status === undefined) {
            status = status;
        }

        //when status is null
        else {
            status = value;
        }

        // Ensure locations is an array
        if (!Array.isArray(location)) {
            location = [location];
        }

        // To iterate each location to get data
        let getLocations = location.map((location) => {
            return Vehicles.getStockWithValue(location, pricing, status).then((result) => {
                return result;
            });
        });

        // Make the locations into single array
        return Promise.all(getLocations)
            .then((results) => {
                const result = results.flat();
                setResults(result);

                // getExportData(result, columns);

                getSupplierId();

                setLoading(false);

                return result;
            })
            .catch((error) => {
                throw error;
            });
    }

    // function getVehicles(value) {

    //     let queries = [
    //         {
    //             field: 'update',
    //             value: value,
    //         },
    //         {
    //             field: 'dealerCode',
    //             value: cityCode[location]
    //         }

    //     ];
    //     return Vehicles.get(queries).then((result) => {

    //         setResults(result.vehicles);

    //         // getSupplierId();

    //         setLoading(false);

    //         return result;
    //     });

    // }

    // Variable to show indicator for loading
    let hide = null;

    /***
     *
     * Get Supplier Id
     */
    function getSupplierId() {
        var queries = [
            {
                field: 'location',
                value: location,
            },
        ];

        Branches.get(queries).then((res) => {
            // console.log(res.branches[0].vas_supplier_id)
            setBranch(res.branches[0]);
        });
    }

    /**
     *
     * @param {*} document
     */
    function handleFile(document) {
        // Removed
        if (document.status !== 'removed') {
            var f = document;

            var reader = new FileReader();

            const rABS = !!reader.readAsBinaryString;

            reader.onload = function (e) {
                //
                hide = message.loading('Comparing data , Please wait');

                const bstr = e.target.result;

                const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });

                const wsname = wb.SheetNames[0];

                const ws = wb.Sheets[wsname];

                const data = XLSX.utils.sheet_to_json(ws, { header: 0 });

                // console.log('Data Retreived');

                retrieveData(data);
            };

            if (rABS) reader.readAsBinaryString(f);
            else reader.readAsArrayBuffer(f);
        } else {
            setFiles([]);

            // setBtnloading(false);
        }
    }

    /**
     * Function compares the new stock with the existing to
     * create objects for comparison
     *
     * @param {*} newVehicles
     */
    async function retrieveData(newVehicles) {
        var old = {};

        let oldVehicles = await getPricing(location, null);

        oldVehicles.forEach((i) => {
            old[i['Vin Number']] = i;
        });

        let v = {};

        newVehicles.forEach((c) => {
            v[c['Vin Number']] = c;
        });

        compareStock(old, v);
    }

    /**
     * Function would check the difference between both stock and
     * prepare the data of the new vehicles as per the stock uploaded
     *
     * @param {*} previous
     * @param {*} latest
     */
    function compareStock(previous, latest) {
        var newstock = [];
        // console.log(latest)

        // console.log('Comparing Stock');

        const updatedStock = {};

        // Find the keys of the current stock
        var previousKeys = Object.keys(previous);

        // StockTransactions.loadFunction("getStock",location)

        // Find the keys of latest stock
        var latestKeys = Object.keys(latest);

        // Save a copy of the latest keys
        var copy = [...latestKeys];

        // For all the previous keys
        previousKeys.forEach((dmsVin, index) => {
            // If its an update to already present vehicle
            var matchingIndex = copy.indexOf(dmsVin);

            // Add the vehicle in the excel to the object to be uploaded
            // updatedStock[index] = latest[index];

            // Each stock in the excel is to be recorded as a dms stock
            // updatedStock[index]['dms_stock'] = 'new';

            // console.log(dmsVin, matchingIndex);

            // copy.splice(index, 1);

            // updatedStock[dmsVin] = previous[dmsVin];

            // Since its repeated , its present in dms stock
            //update the vehicle details with the details from the imported data,
            //But the previous fields in surge should stay the same

            if (matchingIndex !== -1) {
                updatedStock[dmsVin] = {
                    ...previous[dmsVin],
                    ...latest[dmsVin],
                    dms_stock: 'new',
                };

                // updatedStock[dmsVin].update = 'new';

                // Remove the item from the list
                copy.splice(matchingIndex, 1);
            } else {
                updatedStock[dmsVin] = {
                    ...previous[dmsVin],
                    dms_stock: 'remove',
                };
            }
        });
        //If the vehicle is not already in surge then created a new record for the vehicle and keep its dms_stock new
        latestKeys.forEach((v) => {
            if (previousKeys.indexOf(v) === -1) {
                updatedStock[v] = {
                    ...latest[v],
                    dms_stock: 'new',
                };
            }
        });
        //We take all billed cases in surge with dms_stock new and check whether the vehicle is still in dms_stock
        //If it is in dms_stock we keep the status new else we keep dms_stock as remove
        Object.keys(billedVehicles).forEach((x) => {
            if (latestKeys.indexOf(x) === -1) {
                updatedStock[x] = {
                    ...billedVehicles[x],
                    dms_stock: 'remove',
                };
            } else {
                updatedStock[x] = {
                    ...billedVehicles[x],
                    dms_stock: 'new',
                };
            }
        });
        // console.log('Get vehicle data');

        // For each of the rest of vehicles , we have to find the surge stock status
        // before updating
        return Promise.all(
            copy.map((vin, index) => {
                return getCurrentVehicleStatus(vin);
            })
        ).then((vehicles) => {
            // console.log(vehicles);

            vehicles.forEach((vehicle) => {
                if (vehicle.update === 'remove' || vehicle.update === 'bbnd') {
                    //update the vehicle details with the details from the imported data,
                    //But the previous fields in surge should stay the same
                    updatedStock[vehicle.id] = { ...vehicle, ...latest[vehicle.id] };
                } else {
                    // Add the object from the stock
                    updatedStock[vehicle.id] = {
                        ...vehicle,
                        ...latest[vehicle.id],
                    };

                    updatedStock[vehicle.id].update = 'new';

                    updatedStock[vehicle.id].dms_stock = 'new';

                    //  updatedStock[vehicle.id].dealerCode = cityCode[location];
                }

                //If the vehicle is a new stock nit present in stock_transactions
                if (!vehicle.update) {
                    updatedStock[vehicle.id].stock = true;
                    // updatedStock[vehicle.id].dms_stock = 'new';
                    newstock[vehicle.id] = {
                        ...vehicle,
                        ...latest[vehicle.id],
                    };
                }

                // If the vehicle is not  present in dms stock we have to remove it
                if (latestKeys.indexOf(vehicle.id) !== -1) {
                    updatedStock[vehicle.id].dms_stock = 'new';
                } else {
                }
            });

            // console.log(newstock)

            getStockPricing(updatedStock);

            getBilledVehicles(location);

            // For all the vehicles , we add a pdi object in the vehicle
            // to record pdi documents
            Object.keys(updatedStock).map((item) => {
                updatedStock[item] = {
                    ...updatedStock[item],
                    deleted_at: null,
                    deleted_by: null,
                };

                //Get the label to be added, according to full spec code in vehicle
                vehicleSpecConfig.map((config) => {
                    if (config.full_spec_code === updatedStock[item]['Full Spec Code']) {
                        var labels = [];
                        labels.push(config.label);

                        // Add label to vehicle
                        updatedStock[item]['labels'] = labels;
                    }
                });
                // If the pdi is not done ,
                // let it be done .
                if (!updatedStock[item]['pdi']) {
                    updatedStock[item]['pdi'] = { status: 'pending' };
                }

                if (!updatedStock[item]['dealerCode']) {
                    updatedStock[item]['dealerCode'] = cityCode[location];
                }

                if (
                    updatedStock[item]['Booking No'] &&
                    updatedStock[item]['Booking No'].substring(0, 4) !== 'KL30' &&
                    updatedStock[item]['Booking No'].substring(0, 4) !== 'KL50'
                ) {
                    updatedStock[item]['Booking No'] = cityCode[location] + updatedStock[item]['Booking No'];
                    updatedStock[item]['bookingId'] = updatedStock[item]['Booking No'];
                }
            });

            setStock({ ...updatedStock });

            setTimeout(() => {
                setBtnloading(false);
            }, 1000);
        });
    }

    /**
     * Get the vehicle object of the vin
     */
    function getCurrentVehicleStatus(vin) {
        return Vehicles.getRecord(vin).then((vehicle) => {
            return {
                ...vehicle,
            };
        });
    }

    var selling = {};
    const SheetJSFT = [
        'xlsx',
        'xlsb',
        'xlsm',
        'xls',
        'xml',
        'csv',
        'txt',
        'ods',
        'fods',
        'uos',
        'sylk',
        'dif',
        'dbf',
        'prn',
        'qpw',
        '123',
        'wb*',
        'wq*',
        'html',
        'htm',
    ]
        .map(function (x) {
            return '.' + x;
        })
        .join(',');

    /***
     *
     * Get Pricing */
    function getStockPricing(stock) {
        let keys = Object.keys(stock);
        keys.forEach((index) => {
            var id = stock[index]['Variant Code'] + '-' + stock[index]['Color Type'];

            return Pricing.getRecord(id).then((res) => {
                selling[index] = res;
            });
        });
        setPrice(selling);
    }

    /**
     *
     */
    function approve() {
        let keys = Object.keys(stock);

        // let key = Object.keys(transactions)

        setBtnloading(true);

        let hide = message.loading('Uploading stock , Please wait');

        let newStock = {};

        let detailstock = {};

        let blockCall = false;

        let arraywithnosellingprice = [];

        // Filtering the list was not updating db , now we would have the removed stock with status update remove
        // keys = keys.filter((index) => stock[index].update !== 'remove');

        keys.forEach((index) => {
            //Calculations to find tax vallues of the stock

            var igst = price[index].igst;

            var cess = price[index].cess;

            // var tcs = 1 / 100;

            //var amount = stock[index]['Basic Price'] + (stock[index]['Basic Price'] * igst) / 100 + (stock[index]['Basic Price'] * cess) / 100;

            // var final_amount = amount + amount * tcs;

            var final_amount = stock[index]['Basic Price'];

            newStock[index] = stock[index];

            //Fields for Stock Table
            if (stock[index].stock) {
                if (price[index].sellingPrice) {
                    detailstock[index] = {
                        bill_date: new Date(moment(stock[index]['KIN Invoice Date'], 'DD/MM/YYYY`')),
                        bill_number: stock[index]['KIN Invoice No'],
                        color: stock[index]['Exterior Color Name'],
                        location: location,
                        dealerCode: stock[index]['Order Dealer'],
                        expiry_date: null,
                        free_quantity: 0,
                        item_code: stock[index]['Variant Code'] + '-' + stock[index]['Color Type'],
                        item_identity_number: stock[index].id,
                        margin: parseFloat((price[index].sellingPrice - stock[index]['Basic Price']).toFixed(2)),
                        packing: 0,
                        basic_price: parseFloat(stock[index]['Basic Price'].toFixed(2)),
                        stock_value: parseFloat(final_amount.toFixed(2)),
                        purchase_rate: parseFloat(final_amount.toFixed(2)),
                        igst_amount: parseFloat(((stock[index]['Basic Price'] * igst) / 100).toFixed(2)),
                        cess_amount: parseFloat(((stock[index]['Basic Price'] * cess) / 100).toFixed(2)),
                        quantity: 1,
                        received_quantity: 1,
                        replace_quantity: 0,
                        sales_tax: null,
                        selling_price: parseFloat(price[index].sellingPrice.toFixed(2)),
                        profit_amount: parseFloat((price[index].sellingPrice - stock[index]['Basic Price']).toFixed(2)),
                        status: 'active',
                        store_id: null,
                        supplier_id: branch.vas_supplier_id,
                        transaction_code: 15,
                        purchase_date: new Date(moment(stock[index]['KIN Invoice Date'], 'DD/MM/YYYY')),
                        transaction_date: new Date(moment(stock[index]['KIN Invoice Date'], 'DD/MM/YYYY')),
                        model: stock[index]['Model'],
                        variant: stock[index]['Variant'],
                        created_at: new Date(),
                        deleted_at: null,
                        deleted_by: null,
                        sub_mode: 'normal',
                        active: true,
                    };
                } else {
                    arraywithnosellingprice.push(stock[index]['Variant']);
                    blockCall = true;
                }
            }
        });

        console.log(newStock, 'neww');
        console.log(detailstock, 'dee');

        if (!blockCall) {
            //Update Vehicles and stock_transactions with new stocks
            Vehicles.writestock(newStock, detailstock, location).then(() => {
                message.success(`${keys.length} records updated successfully`);

                setStock({});

                setFiles([]);

                getPricing(location, null);

                hide();

                setBtnloading(false);
            });
        } else {
            hide();
            setArrayWithoutSellingPrice(arraywithnosellingprice);
            setIsModalOpen(true);
            setBtnloading(false);
        }
    }

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    function decline() {
        setStock({});
    }

    const uploadProps = {
        onRemove: (file) => {
            var index = files.indexOf(file);

            var newFileList = files.slice();

            newFileList.splice(index, 1);

            setFiles({ ...newFileList });
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                // console.log(info.file, info.fileList);

                // setBtnloading(true);

                handleFile(info.file);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        beforeUpload: (file) => {
            // setBtnloading(true);

            setFiles([...files, file]);

            return false;
        },
        files,
    };

    /**
     * According to view we have to render
     * different block
     *
     * @param {*} view
     */
    function renderTableAccordingToView({ result, view, user }) {
        switch (view) {
            case 'Normal':
                return <StockTable caption={`${result.length} Vehicles`} result={result} columns={columns} user={user} />;

            case 'Yard Wise':
                return (
                    <>
                        <YardWiseStock result={result} columns={columns} user={user} />
                    </>
                );

            case 'Status Wise':
                return (
                    <>
                        {masters.map((master) => {
                            // Filter the stock by stock point
                            let vehicles = result.filter((record) => record.vehicle_status === master.id);

                            return (
                                <>
                                    <StockTable caption={`${master.name}`} result={vehicles} columns={columns} user={user} />
                                </>
                            );
                        })}
                    </>
                );

            default:
                break;
        }
    }

    function YardWiseStock({ result, columns, user }) {
        return (
            <>
                {stockPoints.map((point) => {
                    // Filter the stock by stock point
                    let vehicles = result.filter((record) => record.stock_point_id === point.id);

                    return (
                        <>
                            <StockTable caption={`${point.name}`} result={vehicles} columns={columns} user={user} />
                        </>
                    );
                })}
            </>
        );
    }

    return (
        <div className="generate listing">
            <Modal title="Error" visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <p style={{ fontSize: '16px', fontWeight: 'bold' }}>
                    Please find below vehicles for which pricing was missing . Please sync Price Master from DMS to update the stock .
                </p>
                {arrayWithoutSellingPrice &&
                    arrayWithoutSellingPrice[0] &&
                    arrayWithoutSellingPrice.map((ele) => {
                        return <p>{ele}</p>;
                    })}
            </Modal>

            <div className="page-header">
                <div>
                    <Select
                        mode="multiple"
                        allowClear
                        defaultValue={location}
                         placeholder="Select Locations"
                        style={{ width: location.length === 0 ? "150px":"auto"}}
                        onChange={(location) => {
                            setLocation(location);

                            getYardDetails(location);

                            getPricing(location, null);
                        }}
                    >
                        {user.locations.map((location) => {
                            return <Option value={location}>{location} Stock</Option>;
                        })}
                    </Select>

                    {vehicles.lastUpdated && vehicles.lastUpdated.timestamp ? (
                        <p>
                            <small>
                                Last Updated :{' '}
                                {`${moment(vehicles.lastUpdated.timestamp, 'DD/MM/YYYY HH:mm').format('dddd, MMMM Do YYYY, h:mm:ss a')}`},{' '}
                                <Text type="danger">{`${moment(vehicles.lastUpdated.timestamp, 'DD/MM/YYYY HH:mm').fromNow()}`}</Text>
                            </small>
                        </p>
                    ) : null}
                </div>

                {/* {btnloading ? 'true' : 'false'} */}

                <div className="page-actions">
                    <Select
                        defaultValue={view}
                        style={{ width: '180px' }}
                        onChange={(ele) => {
                            setView(ele);
                        }}
                    >
                        {['Normal', 'Yard Wise', 'Status Wise'].map((ele) => {
                            return <Option value={ele}>{ele}</Option>;
                        })}
                    </Select>
                    {/** select using vehicle status  */}
                    <Select
                        allowClear
                        style={{ width: '180px' }}
                        onChange={(status) => {
                            setValue(status);
                            getPricing(location, status);
                        }}
                    >
                        {masters.map((location, index) => (
                            <Option value={location.identifier} key={index}>
                                {location.name}
                            </Option>
                        ))}
                    </Select>
                    <Upload previewFile={null} accept={SheetJSFT} {...uploadProps}>
                        <Button size={'small'} icon={<UploadOutlined />}>
                            Upload
                        </Button>
                    </Upload>

                    <Button
                        onClick={() => {
                            getPricing(location, null);
                        }}
                        type="secondary"
                        size={'small'}
                    >
                        Refresh
                    </Button>
                </div>
            </div>

            {Object.keys(stock).length ? (
                <div className="file-review">
                    <Divider orientation="left">Stock review after upload</Divider>
                    <div className="action-header">
                        <h4>{Object.keys(stock).filter((entry) => stock[entry]['update']).length} Updates found.</h4>
                        <p>{Object.keys(stock).filter((entry) => stock[entry]['update'] === 'new').length} are new.</p>
                        {/* <p>{Object.keys(stock).filter((entry) => stock[entry]['update'] === 'remove').length} are removed.</p>
                         <p>{Object.keys(stock).filter((entry) => stock[entry]['update'] === 'status').length} has an update in status.</p> */}
                        <Button loading={btnloading} onClick={approve} key="3">
                            Approve
                        </Button>
                        ,
                        <Button loading={btnloading} onClick={decline} key="2">
                            Decline
                        </Button>
                    </div>{' '}
                    <Card header={`${Object.keys(stock).filter((entry) => stock[entry]['update']).length} updates`} size="small">
                        {Object.keys(stock)
                            .filter((entry) => stock[entry]['update'])
                            .map((vin, index) => {
                                return <VehicleCard vehicle={stock[vin]} key={index} index={index} />;
                            })}
                    </Card>{' '}
                </div>
            ) : null}

            {loading ? (
                <PlaceHolder type="listing" />
            ) : (
                <>
                    {' '}
                    <div className="table-container">{renderTableAccordingToView({ result, view, user })}</div>
                </>
            )}
        </div>
    );
}

/**
 * Stock Table to show a caption and allow export of data
 *
 * @param {*} param0
 * @returns
 */
function StockTable({ caption, result, columns, user }) {
    const [expRecord, setExptRecords] = useState();

    const [gbRecord, setGbRecords] = useState();

    const [groupbyResult, setGroupbyResult] = useState(false);

    // The state variable to store the array of search results.
    const [searchList, setSearchList] = useState([]);

    // The state variable to toggle search functionality.
    const [enableSearch, setEnableSearch] = useState(false);
    /**
     * Handles input events for searching results by VIN number
     *
     * @param {Event} event - The input event object
     * @return {void}
     */
    const onInput = (event) => {
        const value = event.target.value.toLowerCase();

        let arr = [];
        if (value === '') {
            // Clear the search results when the input is empty
            setSearchList([]);
            setEnableSearch(false);
        } else {
            /* checking if the value of the 'Vin Number' property in each item contains a specific value. If it does, the item is pushed
       into a new array called `arr`. */
            result.forEach((item) => {
                if (item['Vin Number'] && item['Vin Number'].toLowerCase().includes(value)) {
                    arr.push(item);
                }
            });

            setSearchList(arr);

            setEnableSearch(true);
        }
    };
    /**
     * Returns the count of search results.
     * @type {number}
     */

    const searchResultsCount = enableSearch ? searchList.length : result.length;

    /**
     * This function returns the array that can be downloaded.
     * This function is maintained in firebase-core and when migrating to onedesk, it can be moved
     * @param {*} records
     * @param {*} columns
     */
    async function getExportData(records, columns) {
        let filteredColumns = columns.filter((col) => !col.disableExport);

        const arr = await Promise.all(
            records.map((row, index) => {
                let entry = {
                    'Sl No': index + 1,
                };

                filteredColumns.forEach((column, index) => {
                    if (column.exportDefinition) {
                        entry[column.title] = column.exportDefinition(row, index, index);
                    } else if (column.render) {
                        entry[column.title] = column.render(row, index, index);
                    } else if (column.dataIndex) {
                        entry[column.title] = row[column.dataIndex];
                    } else if (column.field) {
                        entry[column.title] = row[column.field];
                    } else {
                        entry[column.title] = row[column.field];
                    }
                });

                return entry;
            })
        );

        setExptRecords(arr);
    }

    useEffect(() => {
        getExportData(result, columns);
    }, [result]);

    var groupBy = function (xs, key) {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);

            return rv;
        }, {});
    };

    function updateList(e) {
        setGroupbyResult(e.target.checked);
        let records = groupBy(result, 'Model');
        // console.log(records);
        setGbRecords(records);
    }
    return (
        <div className="card">
            <div className="page-header push-end">
                <div style={{ display: 'flex' }}>
                    <h3 style={{ marginRight: '20px' }}>{caption}</h3>
                    <div>
                        <Checkbox value={groupbyResult} onChange={updateList}>
                            Group By Model
                        </Checkbox>
                    </div>
                </div>
                {/* Search VIN number */}
                <Input
                    type="text"
                    placeholder="Search VIN number here"
                    onInput={onInput}
                    prefix={<SearchOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} // Add the search icon
                    style={{ marginBottom: '10px', width: 300 }}
                />
                <div className="page-actions">{expRecord && <ExportReactCSV csvData={expRecord} />}</div>
            </div>

            {groupbyResult ? (
                <GroupedSummary group={gbRecord} columns={columns} user={user} />
            ) : (
                // Object.keys(gbRecord).map((res) => {
                //     return <StockTable caption={`test`} result={gbRecord[res]} columns={columns} user={user} />
                // })
                <Table
                    size="small"
                    scroll={{ x: true }}
                    rowKey={(record) => record.index}
                    // dataSource={result}
                    dataSource={enableSearch ? searchList : result}
                    columns={columns}
                    pagination={{
                        pageSize: 200,
                    }}
                    summary={(pageData) => {
                        if (searchResultsCount > 0) {
                            // Show summary row only for search results
                            return null;
                        }
                        let total = 0;

                        let total_including_gst = 0;

                        pageData.forEach((entry) => {
                            total += parseFloat(entry.stock_value);
                            total_including_gst += parseFloat(entry.stock_value_including_gst);
                        });

                        return (
                            <>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell></Table.Summary.Cell>

                                    <Table.Summary.Cell>
                                        <Title level={5}>Total</Title>
                                    </Table.Summary.Cell>

                                    <Table.Summary.Cell></Table.Summary.Cell>

                                    <Table.Summary.Cell></Table.Summary.Cell>

                                    <Table.Summary.Cell></Table.Summary.Cell>

                                    <Table.Summary.Cell></Table.Summary.Cell>

                                    <Table.Summary.Cell></Table.Summary.Cell>

                                    <Table.Summary.Cell></Table.Summary.Cell>

                                    <Table.Summary.Cell></Table.Summary.Cell>

                                    <Table.Summary.Cell></Table.Summary.Cell>

                                    <Table.Summary.Cell></Table.Summary.Cell>

                                    {user.role === 'accounts' || user.role === 'sm' || user.role === 'edp' ? (
                                        <>
                                            <Table.Summary.Cell>
                                                <Title level={5}>
                                                    <Rupee value={total}></Rupee>
                                                </Title>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <Title level={5}>
                                                    <Rupee value={total_including_gst}></Rupee>
                                                </Title>
                                            </Table.Summary.Cell>
                                        </>
                                    ) : null}
                                </Table.Summary.Row>
                            </>
                        );
                    }}
                ></Table>
            )}
        </div>
    );
}

/**
 *
 */
function GroupedSummary({ group = {}, columns, user }) {
    let grouping = Object.keys(group);

    return (
        <>
            {grouping.map((groupedIndex) => {
                let data = group[groupedIndex];

                // let vehicleTotal =

                let total = 0;

                group[groupedIndex].forEach((entry) => {
                    total += parseFloat(entry.stock_value);
                });

                return (
                    <>
                        <VehicleThumbnail model={groupedIndex} total={total} ele={data} />

                        <StockTable caption={groupedIndex} result={data} columns={columns} user={user} />
                    </>
                );
            })}
        </>
    );
}

function VehicleThumbnail({ model, total, ele, list, user }) {
    return (
        <div className="vehicle-header">
            <img style={{ width: '16%' }} src={images[model]} alt="vehicle-img" />

            <div className="score-board" style={{ display: 'flex', flexDirection: 'column' }}>
                <span style={{ color: 'black', fontWeight: 'bold' }}>{ele.length} Vehicles </span>
                <span style={{ color: 'black', fontWeight: 'bold' }}>Total Amount : {total.toFixed(2)}</span>
                {/* {user.role === 'kec' ? null : (
                    <div span={4} className="score-card">
                        <Statistic title={model} value={ele.length} />
                    </div>
                )} */}

                {/* {Object.keys(list).map((index, key) => {
                    return (
                        <div key={key} span={4} className="score-card">
                            <Statistic title={index} value={list[index].length} />
                        </div>
                    );
                })} */}
            </div>
        </div>
    );
}

function VehicleCard({ vehicle, model, callback, index }) {
    return (
        <div className="stock-item" to={`sm-car-detail/${vehicle['Vin Number']}`}>
            <div className="left">
                {`#${index + 1}`}

                <Title level={4}>
                    {vehicle['Variant']}

                    <span>{` [ ${vehicle['Exterior Color Name']} ] `}</span>
                </Title>

                <Text level={5}>{vehicle['Vin Number']}</Text>

                <div>{`${vehicle['Cust ID'] ? 'Allocated for ' + vehicle['Cust Name'] + ', ' + vehicle['Cust ID'] : ''}`}</div>

                <small className="status">
                    <Badge status={badgeColor[vehicle['Stock Status']]} />

                    {vehicle['Stock Location'] || vehicle['Stock Status']}
                </small>

                <div className="customer-requests">
                    {vehicle.bookings && vehicle.bookings.length > 0 ? <Text type="warning">{vehicle.bookings.length} Customers waiting</Text> : null}
                </div>
            </div>
            <div className="right">
                <Tag color={statusColor[vehicle['update']]}>{vehicle['update']}</Tag>
            </div>
        </div>
    );
}
