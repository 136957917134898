
import React from "react";


import { Tag, Row } from 'antd';


import { Link } from 'react-router-dom'

import './customer-info.scss';

import { modelImages } from "../../modules/global-config";

var images = modelImages;

export default function CustomerInfo({ request = {}, booking = {} }) {

    return (<div className="customer-info vehicle-card left-detail">

        <div className="vehicle-image-wrapper">

            <img src={images[request['model']]} alt="vehicle-img" />

            <p>{request.allocation && request.allocation.vinNo ? request.allocation.vinNo : null}</p>

            <h3>{request['variant']}</h3>

            <small>
                {request['color']}
            </small>




        </div>

        <div gutter={0} className="detail-wrapper">


            <div className="detail-element">
                <Row span={12}>
                    <span>Customer</span>
                </Row>
                <Row span={12}>
                    <h3>
                        <Link to={`/requests/${request.id}`}>
                            {request.customer.customerName}
                        </Link>
                    </h3>
                </Row>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <span>Phone</span>
                </Row>
                <Row span={12}>
                    <h3>{request.customer.phone}</h3>
                </Row>
            </div>


            <div className="detail-element">
                <Row span={12}>
                    KEC
                </Row>
                <Row span={12}>

                    <h3 className="">
                        {request['kec']}
                        {/* {booking['Consultant Name']} */}
                    </h3>
                </Row>
            </div>


            <div className="detail-element">
                <Row span={12}>
                    Team Leader
                </Row>
                <Row span={12}>

                    <h3 className="">
                        {request['teamLeader']}
                        {/* {booking['Team Leader']} */}
                    </h3>
                </Row>
            </div>

        </div>
    </div>)



}