import React, { useEffect, useState, useContext } from 'react';

import { Tag, Button, Popconfirm, message } from 'antd';

import { GlobalContext } from '../../../../../../Store';

import { Link } from 'react-router-dom';

import FirebaseUtils from '../../../../../../utils/firebase.utils';

import BlockRequest from '../../../block-request/block-request';

import moment from 'moment';

import { CheckCircleOutlined } from '@ant-design/icons';

import ViewComment from '../../../../../common/components/view-comment/view-comment';

import { Typography, Modal } from 'antd';

import { Vehicles, Bookings, Settings } from '../../../../../../models/';

import { BookingStatusModal } from '../../../../../accounts/components/booking-status-modal/booking-status-modal';

const { Title, Text } = Typography;

export function RequestCard({ index, request = {}, city, vehicles, callback, access_unblocking }) {
    const { user, disableDisplayPhoneNumber } = useContext(GlobalContext);

    const [booking, setBooking] = useState({});

    const [vehicle, setVehicle] = useState({});

    const [bookingStatusModal, setBookingStatusModal] = useState(false);

    useEffect(() => {
        if (request.bookingNo) {
            getBookingDetail(request.bookingNo);
        }

        if (request.allocation.vinNo) {
            Vehicles.getRecord(request.allocation.vinNo).then((result) => {
                setVehicle(result);
            });
        }

        return () => { };
    }, [request]);

    function getBookingDetail(bookingNo) {
        FirebaseUtils.getBooking(bookingNo).then((result) => {
            var booking = result.data();

            if (booking) {
                setBooking({ ...booking, comments: [] });
            }
        });
    }

    function cancelRequest(city, index) {

        let params = {
            status: 'removed',
        };

        FirebaseUtils.updateRequestGeneric(request.id, params, 'Request is Removed', 'removed', 'requests').then(() => {
            message.success('Request has been removed');

            // If there was a matching booking update the field for the booking
            if (request.bookingNo) {
                let booking = request.bookingNo;

                FirebaseUtils.removeRequest(booking, false).then(() => {
                    // getAllocationRequests(color, variant, city);

                    callback();
                });
            } else {
                callback();
            }
        });
    }

    // Unblocking a vehicle from a request
    async function unblockRequest(request) {

        setBookingStatusModal(true)

        const app = FirebaseUtils.getApp();

        const batch = app.batch();

        await Vehicles.unblockVehicle(vehicle, request, batch).then(async() => {
            await batch.commit();
            // callback();
            message.success('Vehicle Unblocked');
        });

    }

    let allowUnblock = false;

    let isSenior = ['admin', 'sm'].indexOf(user.role) !== -1;

    if (isSenior && (request.billing.status === 'pending' || request.billing.status === 'cancelled') && (access_unblocking)) {
        allowUnblock = true;
    }

    return (
        <div className="request-card allocation-request" key={index}>
            <div className="card">
                <div className="left">
                    <Title level={4}>{request.customer.customerName}</Title>
                    <h3 className="title ">
                        {request.variant} , {request.color}
                    </h3>
                    {/* <Tag color="magenta">{request.status || 'Initial'}</Tag> */}
                    {request.isHighPriority && request.highPriorityStatus === 'approved' ? <Tag color="red">High Priority</Tag> : null}
                    {/*disable PhoneNumber for kec and tl */}
                    {!disableDisplayPhoneNumber ? <h4 className="title">{request.customer.phone}</h4> : null}
                    <h4>{request.city}</h4>
                    <h4>KEC : {request['kec']}</h4>
                    <h4>Team Leader : {request['teamLeader']}</h4>
                    <p className="address">{request.customer.remarks}</p>
                    Created at {request.created_at},{' '}
                    <Text type="danger">{`${moment().diff(moment(request.created_at, 'DD/MM/YYYY'), 'days')} days ago.`}</Text> By{' '}
                    {request.created_by_name}
                    <p className="">
                        Booked on {booking['Booking Date']},{' '}
                        <Text type="danger">{`${moment().diff(moment(booking['Booking Date'], 'DD/MM/YYYY'), 'days')} days ago.`}</Text>
                    </p>
                    Committed Delivery Date {booking['Committed Delivery Date']}
                </div>
                <div className="right card-buttons">
                    {request.allocation && request.allocation.vinNo ? (
                        <>
                            {allowUnblock ? (
                                <Popconfirm
                                    title="Are you sure you want to Unblock this Vehicle ? "
                                    onConfirm={() => {
                                        unblockRequest(request);
                                    }}
                                    onCancel={() => { }}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button type="secondary">Unblock</Button>{' '}
                                </Popconfirm>
                            ) : null}

                            <Tag icon={<CheckCircleOutlined />} color="success">
                                Blocked
                            </Tag>
                        </>
                    ) : (
                        <>
                            {(['admin', 'rm', 'sm'].indexOf(user.role) !== -1) ? (
                                <BlockRequest index={request.id} vehicles={vehicles} city={city} request={request} callback={callback} />
                            ) : null}
                        </>
                    )}

                    {request.created_by === user.id && request.allocation && !request.allocation.vinNo && !(request.status === 'removed')? (
                        <Button size="small" onClick={() => cancelRequest(request.city)} type="secondary">
                            Cancel
                        </Button>
                    ) : null}

                    <ViewComment id={request.id} />

                    <Link to={`/requests/${request.id}`}>View Request</Link>

                    <a className="card-action" href={`tel:${request.customer.phone}`} key="list-loadmore-edit">
                        Call
                    </a>

                    {/* update booking current status value when unblocking the vehicle  */}
                    {bookingStatusModal ?
                        (
                            <div>
                                <Modal
                                    width={'50%'}
                                    destroyOnClose={false}
                                    footer={null}
                                    title="Booking Status"
                                    visible={bookingStatusModal}
                                    okText="Okay"
                                    onOk={() => {
                                        // setBookingStatusModal(false);
                                    }}
                                    onCancel={() => {
                                        // setBookingStatusModal(false);
                                    }} >

                                    <BookingStatusModal id={request.bookingNo} callback={callback} />
                                </Modal>
                            </div>
                        ) : null}
                    {/*Ends */}

                </div>
            </div>
        </div>
    );
}
