
import React, { useState, useEffect, useContext } from "react";


import { GlobalContext } from '../../../../Store';

import { Link } from 'react-router-dom'

import { Row, Tabs, Button, Tag, DatePicker } from "antd";


import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import FirebaseUtils from '../../../../utils/firebase.utils';

import "./collections.scss";

import moment from 'moment';

const { TabPane } = Tabs;

const dateFormat = 'DD/MM/YYYY';

export default function Collections({ history }) {

    const { user } = useContext(GlobalContext);

    const [today, setToday] = useState(moment())

    useEffect(() => {

    }, [user])

    function callback(key) {
        console.log(key);
    }

    const actions = <DatePicker inputReadOnly allowClear={false} format={dateFormat} value={today} onChange={(result) => {

        setToday(result);
    }} />

    return (
        <section className="finance">


            <Tabs defaultActiveKey="1" onChange={callback} tabBarExtraContent={actions}>

                {
                    user.locations.map((location, index) => {

                        return (
                            <TabPane tab={`Todays Collections`} key={index}>

                                <CollectionList today={today} history={history} url={location} />

                            </TabPane>
                        )
                    })
                }

            </Tabs>

        </section>
    );
}


const CollectionList = ({ url, history, today }) => {

    const { user } = useContext(GlobalContext);

    const [collections, setCollections] = useState([]);

    const [loader, setLoader] = useState(true);

    useEffect(() => {

        getData(today);

    }, [today])


    function getData(today) {

        setLoader(true);

        let queries = [
            {
                field: 'teamleader_id',
                value: user.employee_id

            },
            {
                field: 'transaction_date',
                operator:'>=',
                value: moment(today).startOf('day').valueOf()
            },
            {
                field: 'transaction_date',
                operator:'<=',
                value: moment(today).endOf('day').valueOf()
            }
        ]

        FirebaseUtils.getCollections(url, queries).then((result) => {

            setLoader(false);

            console.log(result);

            setCollections(result);

        });
    }

    return (<><div className="list-header">

        <p className="page-desc">
            {loader ? 'Loading data' : `Found ${(collections).length} results`}
        </p>

        <div className="list-actions">
            <Button onClick={() => { getData() }} type="secondary" size={'small'}>Refresh</Button>
            
            {/* <Button onClick={() => { history.push('/collections/add') }} type="primary" size={'small'}>Add</Button> */}
        </div>

    </div>

        <Row className="report-listing-wrapper">
            {loader ? (
                <PlaceHolder type="listing" />
            ) : (
                    <>
                        {
                            collections.map((report, index) => {
                                return <CollectionCard city={url} request={report} index={index} key={index} />
                            })
                        }
                    </>
                )}
        </Row>


    </>)
}


function CollectionCard({ city, request = {} }) {

    return (<Link className="request-card" to={`${request.city}/collections/${request.collectionId}`}>
        <div className="card">

            <h2 className="title">
                {request.name}

            </h2>

            <h3 className="title amount">
                {request.amount}

            </h3>

            <h4 className="title ">

                {request.phone}

            </h4>

            <h4>
                {request.remarks}
            </h4>

            <p className="address">
                <Tag color="magenta">{request.status || 'Initial'}</Tag>
                {/* 
                <Badge status="processing" text={`${request.matching} Vehicles Matching`} /> */}
            </p>


            Created at {request.created_at} by {request.created_by_name}
        </div>
    </Link>)
}
