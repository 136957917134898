import React, { useEffect, useState, Fragment, useContext } from "react";


import { Link } from 'react-router-dom'


import { Tag, Tabs, Badge, Button, Row, List, Steps, Card, Alert, message, Form } from 'antd';

import { Comment, Avatar, Input } from 'antd';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder'

import Header from '../../../../components/page-header/page-header';

import { GlobalContext } from '../../../../Store';

import FirebaseUtils from '../../../../utils/firebase.utils';

import CommentBlock from './../../../common/components/comment-block/comment-block';

import moment from 'moment';

import {
    CheckCircleOutlined,
} from '@ant-design/icons';

import './high-priority-detail.scss';

import { Typography } from 'antd';

const { Title, Text } = Typography;

const { TextArea } = Input;


var badgeColor = {
    'Allocated': 'error',
    'In transit': 'warning',
    'Free Stock': 'success'
}

const layout = {
    labelCol: {
        xs: { span: 8 },
        sm: { span: 8 },
        lg: { span: 8 }
    },
    wrapperCol: {
        xs: { span: 8 },
        sm: { span: 8 },
        lg: { span: 8 }

    },
};

const { TabPane } = Tabs;

const { Step } = Steps;




export default function HighPriority(props) {

    const [form] = Form.useForm();

    const [bookingForm] = Form.useForm();

    const { user } = useContext(GlobalContext);

    const [file, setFile] = useState({});

    const [status, setStatus] = useState(0);

    const [booking, setBooking] = useState({});

    const [request, setRequest] = useState({ created_by: {} });

    const [vehicle, setVehicle] = useState({});

    const [matching, setMatching] = useState([]);

    const { match } = props;

    const { params } = match;

    const { id, city } = params;

    // const city = user.locations[0];

    const [loader, setLoader] = useState(true)

    useEffect(() => {

        loadData();

    }, [])


    function loadData() {

        setLoader(true);

        console.log(city, id);

        FirebaseUtils.getHighPriority({ city, id }).then((result) => {

            var booking = { ...result.data(), ...{ id: result.id } };

            console.log(booking);

            setFile(booking);

            // setBooking({ ...booking, comments: [] });

            setLoader(false);

            if (booking.bookingNo) {

                getBooking(booking.bookingNo);

            } else {

                if (booking.phone) {

                    searchWithPhone(booking.phone);

                }
            }


            if (booking.requestId) {
                // getRequest(booking);
            } else {

                findMatchingVehicles(booking)
            }

        });
    }

    function searchWithPhone(phone) {


        let params = {
            field: 'Contact Number',
            value: phone
        }


        FirebaseUtils.searchBookings(city, params).then((result) => {

            var bookings = Object.keys(result.bookings);

            if (bookings.length) {


            } else {

            }
        });
    }


    function getBooking(id) {

        FirebaseUtils.getBooking(id).then((result) => {

            var booking = result.data();

            if (booking) {

                console.log(booking);

                setBooking({ ...booking, comments: [], isValid: true });

            } else {

                setBooking({ isValid: false });

            }

            // if (booking.requestId) {
            //     getRequest(booking);
            // } else {

            //     findMatchingVehicles(booking)
            // }
        });
    }

    /**
 * 
 * Find matching vehicles for request
 * 
 * @param {*} request 
 */
    function findMatchingVehicles(booking) {

        if (booking['model'] && city) {
            FirebaseUtils.getPossibleVehicles(booking['model'], city).then((result) => {

                // console.log(result);

                setVehicle(result);

                setLoader(false);


            });
        } else {
            message.error('Failed due to an error');
        }
    }


    function cancelRequest() {

        let params = {
            status: 'removed',
        };

        FirebaseUtils.updateRequestGeneric(file.requestId, params, 'Request is Removed', 'removed', 'requests').then(() => {
            // If there was a matching booking update the field for the booking
            if (file.bookingNo) {

                // let booking = request.booking;

                FirebaseUtils.removeRequest(file.bookingNo, false).then(() => {

                    request.requestId = null;

                    setRequest({ ...request });

                    booking.requestId = null;

                    setBooking({ ...booking });

                    message.success('Request has been removed');


                })
            }
        });
    }

    /**
     * 
     * Allocate already existing booking to 
     * a vehicle 
     * 
     * @param {*} param0 
     */
    function blockVehicle(vehicle) {

        // console.log(booking);

        let request = {
            highPriority: true,
            // verified: true,


            // status: (user.role === 'tl') ? 'Booking Verified' : 'Verification Pending',
            // vinNo: vehicle['Vin Number'],
            // verified: true, // so that the finance process starts
            model: booking['Model'],
            color: booking['Color'],
            city: city,
            variant: booking['Variant'],
        }

        if (booking.bookingNo) {

            request = {
                ...request,
                ...{
                    verified: true,
                    bookingNo: booking.bookingNo,
                    status: 'Booking Verified'
                }
            }

        } else {

            request = {
                ...request,
                ...{
                    status: 'Verification Pending'
                }
            }
        }

        // if (user.role === 'tl') {
        //     request.verified = true;
        // }

        let customer = {
            customerName: booking['Name of the Customer'],
            phone: booking['Contact Number'],
            // place: booking['Ship To Add'],
            customerID: booking['Customer ID']
        }

        let newBooking = {
            vinNo: vehicle['Vin Number'],
            kec: booking['Consultant Name'],
            teamLeader: booking['Team Leader'],
            created_by: user,
            deleted_at:null,
            deleted_by:null,
            ...request,
            status: 'Verification Pending',
            ...{
                bookingNo: booking['Booking No']
            },
            ...{
                customer: customer,
                // expectedDate: booking['Committed Delivery Date'],
                created_at: moment().format('DD/MM/YYYY HH:mm')
            }
        };

        if (booking['Committed Delivery Date']) {

            newBooking.expectedDate = booking['Committed Delivery Date'];
        }

        FirebaseUtils.addRequest(newBooking).then((result) => {


            FirebaseUtils.updateHighPriority(city, file.id, result.id).then(() => {

                loadData();

            });

            // console.log(result);
            if (booking.bookingNo) {

                FirebaseUtils.updateBooking(booking.bookingNo, true, result.id).then(() => {

                    newBooking.requestId = result.id

                    booking.requestId = result.id;

                    setBooking({ ...booking });

                    message.info('Request has been created');

                    setRequest({ ...newBooking });


                })
            } else {

                message.info('Request has been created');

            }
        });
    }

    const onVerify = props => {

        var values = form.getFieldsValue();

        var isCancel = (Object.keys(props).length === 0);

        let content = {
            author: user.name,
            content: isCancel ? "Request Rejected : " : 'Request Approved : ' + values.remarks,
            datetime: moment().format('DD/MM/YYYY HH:mm'),
            deleted_at:null,
            deleted_by:null
        }

        FirebaseUtils.verifyHighPriority(city, file.id, content, isCancel).then((result) => {

            if (result) {
                message.success('Your request has been submitted');
            } else {
                message.success('This request has been rejected');
            }

            loadData();

            // props.history.goBack();
        }).catch((error) => {

            console.log(error);

        });
    };


    const onSubmitBookingNumber = values => {

        FirebaseUtils.updateHighPriorityBookingNumber(city, file.id, values.bookingNo).then((result) => {

            if (result) {
                message.success('Your request has been submitted');
            } else {
                message.success('This request has been rejected');
            }

            loadData();

            // props.history.goBack();
        }).catch((error) => {

            console.log(error);

        });
    };


    return (
        <div className="high-priority-detail">

            <div className="page-header">
                <Title level={3}>
                    High Priority Customer
                </Title>

                <div>
                    <Button onClick={loadData} type="secondary" size={'small'}>Refresh</Button>
                </div>
            </div>

            {
                loader ?
                    <PlaceHolder type="report" />
                    :
                    <Fragment>

                        <div className="page-content">

                            <div className="split-container">

                                <div className="left">

                                    {/* Vehicle Card */}
                                    <div className="vehicle-card">

                                        <Tag color="purple">
                                            {file.status || 'Initial'}
                                        </Tag>

                                        <div className="vehicle-image-wrapper">

                                            {/* <img src={images[file['model']]} alt="vehicle-img" /> */}

                                        </div>

                                        <div gutter={0} className="detail-wrapper">


                                            <div className="detail-element">
                                                <Row span={12}>
                                                    <span>Customer</span>
                                                </Row>
                                                <Row span={12}>
                                                    <h3>{file['name']}</h3>
                                                </Row>
                                            </div>

                                            <div className="detail-element">
                                                <Row span={12}>
                                                    <span>Phone</span>
                                                </Row>
                                                <Row span={12}>
                                                    <h3>{file['phone']}</h3>
                                                </Row>
                                            </div>

                                            <div className="detail-element">

                                                <Row span={12}>
                                                    <span>Place</span>

                                                </Row>
                                                <Row span={12}>
                                                    <h3>{file['place']}</h3>

                                                </Row>

                                            </div>

                                            <div className="detail-element">

                                                <Row span={12}>
                                                    <span>
                                                        Variant
                                                    </span>
                                                </Row>
                                                <Row span={12}>
                                                    <h3>{file['variant']}</h3>
                                                </Row>
                                            </div>

                                            <div className="detail-element">
                                                <Row span={12}>
                                                    Exterior Color Name
            </Row>
                                                <Row span={12}>

                                                    <h3 className="">
                                                        {file['color']}
                                                    </h3>
                                                </Row>
                                            </div>

                                            <div className="detail-element">
                                                <Row span={12}>
                                                    Created By
                                                </Row>
                                                <Row span={12}>

                                                    <h3 className="">
                                                        {file['created_by']}
                                                    </h3>
                                                </Row>
                                            </div>


                                            <div className="detail-element">
                                                <Row span={12}>
                                                    Created At
                                                </Row>
                                                <Row span={12}>

                                                    <h3 className="">
                                                        {file['created_at']}
                                                    </h3>
                                                </Row>
                                            </div>

                                            <div className="detail-element">
                                                <Row span={12}>
                                                    Remarks
                                                </Row>
                                                <Row span={12}>

                                                    <h3 className="">
                                                        {file['remarks']}
                                                    </h3>
                                                </Row>
                                            </div>

                                            {/* <div className="detail-element">
                                                <Row span={12}>
                                                    Committed Delivery Date
                                                </Row>
                                                <Row span={12}>

                                                    <h3 className="">
                                                        {booking['Committed Delivery Date']}
                                                    </h3>
                                                </Row>
                                            </div> */}

                                        </div>
                                    </div>

                                </div>
                                <div className="right">


                                    {
                                        ['rm', 'admin', 'sm'].indexOf(user.role) !== -1 ? <>


                                            {
                                                !file.bookingNo ?
                                                    <Card className="">
                                                        <div style={{ marginTop: '10px' }}>

                                                            <p>
                                                                Please provide the Booking Number for this request
                                                        </p>

                                                            <Form
                                                                {...layout}
                                                                form={bookingForm}
                                                                name="new-record"
                                                                onFinish={onSubmitBookingNumber}
                                                                layout="vertical"
                                                                // validateMessages={validateMessages} 
                                                                initialValues={{
                                                                }}>

                                                                <Form.Item name={'bookingNo'} label="Booking Number" rules={[{ required: true, message: 'Please enter Booking Number' },]}>
                                                                    <Input />
                                                                </Form.Item>

                                                                <Button style={{ marginRight: '10px' }} type="primary" htmlType="submit" className="submit-button">
                                                                    SUBMIT
                                                            </Button>


                                                            </Form>
                                                        </div>
                                                    </Card>
                                                    :
                                                    <>

                                                        {
                                                            booking.isValid ?
                                                                <BookingCard booking={booking} />
                                                                :
                                                                <>

                                                                    Invalid Booking Number {file.bookingNo}
                                                                </>}
                                                    </>
                                            }

                                        </> : <>



                                            </>
                                    }


                                    {
                                        ['rm', 'admin'].indexOf(user.role) !== -1 ? <>


                                            {
                                                file.status === 'requested' ?
                                                    <div style={{ marginTop: '10px' }}>

                                                        <p>
                                                            Please provide your approval after checking with the customer to proceed with the request.
                                                        </p>

                                                        <Form
                                                            {...layout}
                                                            form={form}
                                                            name="new-record"
                                                            onFinish={onVerify}
                                                            layout="vertical"
                                                            // validateMessages={validateMessages} 
                                                            initialValues={{
                                                            }}>

                                                            <Form.Item name={'remarks'} label="Remarks" rules={[{ required: true, message: 'Please enter Remarks' },]}>
                                                                <TextArea rows={4} />
                                                            </Form.Item>

                                                            <Button style={{ marginRight: '10px' }} type="primary" htmlType="submit" className="submit-button">
                                                                APPROVE
                                                    </Button>

                                                            <Button onClick={() => { onVerify(true) }} type="danger" className="submit-button">
                                                                DECLINE
                                                    </Button>

                                                        </Form>
                                                    </div>
                                                    :
                                                    <Alert
                                                        message={`${['approved', 'done'].indexOf(file.status) !== -1 ? 'Approval accepted' : 'Approval Rejected'}`}
                                                        description={`${file.status === 'approved' ? 'This customers case has been approved' : 'This customers case has been rejected'}`}
                                                        type={file.status === 'approved' ? 'success' : 'error'}
                                                    />
                                            }



                                        </> : <>



                                            </>
                                    }






                                    {/* Approval Pending */}
                                    {
                                        file.status === 'requested' ? <Alert
                                            message="Approval Pending"
                                            description={`This request is yet to be approved from the concerned department.`}
                                            type="error"
                                        />
                                            :
                                            null
                                    }


                                    {
                                        !request.empty && file.requestId && !(request.status === 'removed') ?
                                            <Card className="">

                                                <>
                                                    <Tag icon={<CheckCircleOutlined />} color="success">
                                                        Request Created
                                                      </Tag>

                                                    {
                                                        user.role === 'kec' ?
                                                            <Link to={`/requests/${file.requestId}`}>
                                                                View Request
                                                            </Link>

                                                            :
                                                            <Link to={`/requests/${file.requestId}`}>
                                                                View Request
                                                             </Link>
                                                    }

                                                </>

                                                {
                                                    !request.empty
                                                        && file.requestId
                                                        &&!(request.status === 'removed') 
                                                        && request.created_by
                                                        && (request.created_by.name === user.name)
                                                        && request.allocation && !request.allocation.vinNo
                                                        ? <Button size="small" onClick={() => cancelRequest()} type="secondary">Cancel Request</Button> : null
                                                }

                                            </Card>
                                            :
                                            null
                                    }



                                    {request.empty ? <Alert
                                        message="Request has error"
                                        description={`We tried to fetch your request but we ran into some error. You might have to create a request again.`}
                                        type="error"
                                    /> : null}


                                    {/* Show matching vehicles if no vehicle is allocated */}
                                    {
                                        !file.requestId || request.empty ? <>


                                            <Title level={3} className="tab-header">
                                                Matching Vehicles
                                            </Title>

                                            <Tabs onChange={() => { }}>
                                                {/* !allocated['Vin Number'] && userRequest.bookingNo ? */}

                                                <TabPane tab={`Similar (${Object.keys(vehicle).filter((key) => (vehicle[key]['Variant'] === file['variant']) && (vehicle[key]['Exterior Color Name'] === file['color'])).length})`} key="0">

                                                    <List
                                                        size="small"
                                                        dataSource={Object.keys(vehicle).filter((key) => (vehicle[key]['Variant'] === file['variant']) && (vehicle[key]['Exterior Color Name'] === file['color']))}
                                                        renderItem={car => <VehicleCard
                                                            blockVehicle={() => { blockVehicle(vehicle[car]) }}
                                                            city={match.params.city}
                                                            vehicle={vehicle[car]}
                                                            booking={file}

                                                        />}
                                                    />

                                                </TabPane>

                                                <TabPane tab={`By Variant (${Object.keys(vehicle).filter((key) => (vehicle[key]['Variant'] === file['variant'])).length})`} key="1">

                                                    <List
                                                        size="small"
                                                        dataSource={Object.keys(vehicle).filter((key) => (vehicle[key]['Variant'] === file['variant']))}
                                                        renderItem={car => <VehicleCard
                                                            blockVehicle={() => { blockVehicle(vehicle[car]) }}
                                                            city={match.params.city}
                                                            vehicle={vehicle[car]}
                                                            booking={file}
                                                        />}
                                                    />

                                                </TabPane>

                                                <TabPane tab={`By Colour (${Object.keys(vehicle).filter((key) => (vehicle[key]['Exterior Color Name'] === file['color'])).length})`} key="2">

                                                    <List
                                                        size="small"
                                                        dataSource={Object.keys(vehicle).filter((key) => (vehicle[key]['Exterior Color Name'] === file['color']))}
                                                        renderItem={car => <VehicleCard
                                                            blockVehicle={() => { blockVehicle(vehicle[car]) }}
                                                            city={match.params.city}
                                                            vehicle={vehicle[car]}
                                                            booking={file}
                                                        />}
                                                    />

                                                </TabPane>

                                                <TabPane tab={`By Model (${Object.keys(vehicle).length})`} key="3">

                                                    <List
                                                        size="small"
                                                        dataSource={Object.keys(vehicle)}
                                                        renderItem={car => <VehicleCard
                                                            blockVehicle={() => { blockVehicle(vehicle[car]) }}
                                                            city={match.params.city}
                                                            vehicle={vehicle[car]}
                                                            booking={file}
                                                        />}
                                                    />

                                                </TabPane>
                                            </Tabs>

                                        </> : null
                                    }


                                    <Title level={4}>
                                        Comments
                                    </Title>

                                    <CommentBlock id={file.id} />

                                </div>
                            </div>

                        </div>
                    </Fragment>}
        </div>
    )
}



function VehicleCard({ vehicle, unblock, booking, blockVehicle }) {

    const { user } = useContext(GlobalContext);

    return (<div className="vehicle-item" >
        <div className="left">

            <Text level={1}>
                {vehicle['Variant']}
                <span>
                    {` [ ${vehicle['Exterior Color Name']} ] `}
                </span>
            </Text>

            <div>
                {`${vehicle['Vin Number']}`}
            </div>

            <small className="status">

                <Badge status={badgeColor[vehicle['Stock Status']]} />


                {vehicle['Stock Location'] || vehicle['Stock Status']}
            </small>

        </div>
        {/* <div className="right">

            {
                vehicle.allocated && vehicle.allocated.customer ?
                    <div>

                        <Button size="small" onClick={() => { unblock(vehicle) }} type="secondary">Unblock</Button>

                        <Tag icon={<CheckCircleOutlined />} color="success">
                            Blocked
                        </Tag>
                    </div>
                    :
                    <div>

                        {
                            (booking.status === 'approved')
                                &&
                                (booking.bookingNo)
                                ?
                                <Button size="small" type="secondary" onClick={blockVehicle}>
                                    Block Vehicle
                                    {/* {user.role === 'tl' ? 'Send Request to SM' : 'Request TL to Block'} */}
                                {/* </Button>
                                :
                                null
                        }



                        {/* <BlockVehicle vehicle={vehicle} requests={requests} city={city} callback={callback} /> */}
                    {/* </div> */}
            {/* } */}

        {/* </div>  */} 
    </div>)
}





function BookingCard({ booking }) {

    const { user } = useContext(GlobalContext);

    return (<div className="booking-card allocation-request" >
        <div className="card">

            <div className="left">

                <Title level={4}>
                    {booking['Name of the Customer']}
                </Title>

                <h4 className="title">
                    {booking['Contact Number']}
                </h4>

                <h3 className="title ">
                    {booking['Variant']}
                </h3>

                <div>
                    <small> {booking['Color']}</small>
                </div>

                <h4>
                    {booking['Booking No']} - <Tag color="#2db7f5">
                        {/* {dealer[booking['Dealer Code']]} */}
                    </Tag>
                </h4>

                <h4>
                    KEC : {booking['Consultant Name']}
                </h4>


                <h4>
                    Team Leader : {booking['Team Leader']}
                </h4>
                <p className="">
                    Booked on {booking['Booking Date']}, <Text type="danger">{`${moment().diff(moment(booking['Booking Date'], "DD/MM/YYYY"), 'days')} days ago.`}</Text>

                    <Tag color="magenta">{booking.status || 'Initial'}</Tag>

                </p>

                Committed Delivery Date  {booking['Committed Delivery Date']} , Booked By {booking['Consultant Name']}

            </div>
            <div className="right card-buttons">

                <a className="card-action" href={`tel:${booking['Contact Number']}`} key="list-loadmore-edit">Call</a>

            </div>
        </div>

    </div>)
}