import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import './stock-status.scss';

import { Statistic, Col } from 'antd';

import { List, Typography, Button } from 'antd';

import { Collapse } from 'antd';

import PlaceHolder from './../../../../../components/ui_elements/PlaceHolder';

import FirebaseUtils from './../../../../../utils/firebase.utils';

import moment from 'moment';

import { modelImages } from '../../../../global-config';

const { Panel } = Collapse;

const { Text } = Typography;

var images = modelImages;

export default function StockStatus({ url }) {
    const [stock, setStock] = useState({ vehicles: {}, lastUpdated: {} });

    const [loading, setLoading] = useState(true);

    var groupBy = function (xs, key) {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };

    useEffect(() => {
        loadData();
    }, []);

    function loadData() {
        setLoading(true);

        if (url === 'all') {
            FirebaseUtils.getAllVehicles().then((result) => {
                setLoading(false);


                var vehicles = result.all.filter((entry) => entry.dms_stock === 'new' && entry.update);

                var arr = Object.keys(vehicles).map((entry) => {
                    return vehicles[entry];
                });

                console.log(arr);

                var models = groupBy(arr, 'Model');

                console.log(models);

                setStock({ vehicles: models });
            });
        } else {
            FirebaseUtils.getVehicles(url).then((result) => {
                setLoading(false);

                var { vehicles } = result;

                var vehicles = result.vehicles.filter((entry) => entry.dms_stock === 'new' && entry.update);

                var arr = Object.keys(vehicles).map((entry) => {
                    return vehicles[entry];
                });

                var models = groupBy(arr, 'Model');

                console.log(models);

                setStock({ vehicles: models, lastUpdated: result.lastUpdated });
            });
        }
    }

    function getUpdate(previous, latest) {
        const updatedStock = {};

        var previousKeys = Object.keys(previous);

        var latestKeys = Object.keys(latest);

        var copy = [...latestKeys];

        // console.log(copy);

        previousKeys.forEach((first, index) => {
            // If its an update to already present vehicle
            var matchingIndex = latestKeys.indexOf(first);

            // If there is a match
            if (matchingIndex !== -1) {
                copy.splice(index, 1);

                // No update in stock status
                if (previous[first]['Stock Status'] === latest[first]['Stock Status']) {
                    // updatedStock.push(previous[first]);

                    updatedStock[first] = latest[first];
                } else {
                    // Update in stock present

                    // console.log('Update in Stock', previous[first], latest[first]);

                    updatedStock[first] = latest[first];
                }
            } else {
                // If there is no match , probably the item is removed from
                console.log(first, 'is missing');
                updatedStock[first] = previous[first];
            }
        });

        // Add the new vehicles to stock
        copy.forEach((index) => {
            updatedStock[index] = latest[index];
        });

        return updatedStock;
    }

    return (
        <div className="stock-status">
            <div className="page-header">
                {stock.lastUpdated && stock.lastUpdated.timestamp ? (
                    <p>
                        <small>
                            Last Updated : {`${moment(stock.lastUpdated.timestamp, 'DD/MM/YYYY HH:mm').format('dddd, MMMM Do YYYY, h:mm:ss a')}`},{' '}
                            <Text type="danger">{`${moment(stock.lastUpdated.timestamp, 'DD/MM/YYYY HH:mm').fromNow()}`}</Text>
                        </small>
                    </p>
                ) : null}

                <div className="action">
                    <Button onClick={loadData} type="secondary" size={'small'}>
                        Refresh
                    </Button>
                </div>
            </div>

            {loading ? (
                <PlaceHolder type="listing" />
            ) : (
                <Collapse expandIcon={null} className="" ghost>
                    {Object.keys(stock.vehicles).map((vehicle) => {
                        var ele = stock.vehicles[vehicle];

                        var stockStatus = groupBy(ele, 'Stock Status');

                        return (
                            <Panel showArrow={false} className="collapse-parent" header={VehicleThumbnail({ vehicle, ele, list: stockStatus })}>
                                <Collapse className="">
                                    {Object.keys(stockStatus).map((item) => {
                                        var cars = stockStatus[item];

                                        return (
                                            <Panel header={`${item} (${cars.length})`}>
                                                <List size="small" dataSource={cars} renderItem={(car) => <Vehicle city={url} car={car} />} />
                                            </Panel>
                                        );
                                    })}
                                </Collapse>
                            </Panel>
                        );
                    })}
                </Collapse>
            )}
        </div>
    );
}

function Vehicle({ car, city }) {
    return (
        <div className="vehicle-card">
            <div>
                <h4>{car['Variant']}</h4>
                <p>{car['Exterior Color Name']}</p>

                {car['Stock Status'] === 'Allocated' && car['Cust Name'] ? (
                    <>
                        Allocated to {car['Cust Name']} for Booking <Link to={`/bookings/${car['Booking No']}`}>{car['Booking No']}</Link>
                    </>
                ) : null}
            </div>

            <div>
                <small>{car['Stock Location']}</small>
            </div>
        </div>
    );
}

function VehicleThumbnail({ vehicle, ele, list }) {
    return (
        <div className="vehicle-header">
            <img src={images[vehicle]} alt="vehicle-img" />

            <div className="score-board">
                <Col>
                    <Statistic title={vehicle} value={ele.length} />
                </Col>

                {Object.keys(list).map((index, key) => {
                    return (
                        <div key={key} span={4} className="score-card">
                            <Statistic title={index} value={list[index].length} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
