import React, { useEffect, useState, Fragment, useContext } from 'react';

import { Tabs, Button, Row, List, Select, Divider, Tag } from 'antd';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import { GlobalContext } from '../../../../Store';

import { useLocation } from 'react-router-dom';

import { VehicleCard } from './components/vehicle-card/vehicle-card';

import { BookingCard } from './components/booking-card/booking-card';

import { RequestCard } from './components/request-card/request-card';

import './preference-detail.scss';

import { Vehicles, Requests, Bookings, StatusMasters, Settings } from './../../../../models';

import { Typography } from 'antd';

import {  cityKeys, modelImages } from '../../../global-config';

const { Title } = Typography;

const { Option } = Select;

var cityKey = cityKeys

var images = modelImages;

const { TabPane } = Tabs;

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function PreferenceDetail(props) {
    const { user } = useContext(GlobalContext);

    let query = useQuery();

    const [requests, setRequests] = useState([]);

    const [preference, setPreference] = useState({});

    const [vehicle, setVehicle] = useState({});

    const [matching, setMatching] = useState([]);

    const { match } = props;

    const { params } = match;

    const { city } = params;

    const [loader, setLoader] = useState(true);

    const [status, setStatus] = useState([])

    //who all can unblocking-allowed
    const [access_unblocking, setAccessUnblocking] = useState();


    useEffect(() => {
        loadPageData();
        getBookingStatus();
    }, []);


    useEffect(() => {
        // Load who all can unblocking-allowed
        Settings.getValue('unblocking-allowed').then((result) => {
            var allowed = result.value.indexOf(user.email) !== -1;
            setAccessUnblocking(allowed);
        });

        return () => { };
    }, []);

    function loadPageData() {
        const { params } = match;

        const { city } = params;

        setRequests([]);

        setLoader(true);

        let preference = {
            model: query.get('model'),
            variant: query.get('variant'),
            color: query.get('color'),
            city: city,
        };

        setPreference(preference);

        if (city === 'all') {
            var queries = [
                {
                    field: 'Exterior Color Name',
                    value: preference.color,
                },
                {
                    field: 'Variant',
                    value: preference.variant,
                },
            ];

            Vehicles.getAllAvailableStock(queries).then((result) => {
                updateData(preference, result.all);
            });
        } else {
            var vehicleQueries = [
                {
                    field: 'Exterior Color Name',
                    value: preference.color,
                },
                {
                    field: 'Variant',
                    value: preference.variant,
                },

                {
                    field: 'update',
                    value: 'new',
                },


                // {
                //     field: 'Stock Status',
                //     value: ['Free Stock', 'In transit', 'Allocated'],
                //     operator: 'in',
                // },
            ];

            var dealerQueries = [
                {
                    field: 'dealerCode',
                    value: cityKey[city],
                },
            ];

            Vehicles.getAvailableStock(dealerQueries, vehicleQueries).then((result) => {
                updateData(
                    preference,
                    result.all.filter((vehicle) => {
                        return ['remove', "bbnd"].indexOf(vehicle.update) === -1;
                    })
                );
            });
        }
    }

    function updateData(preference, vehicles) {
        setLoader(false);

        setPreference(preference);

        setVehicle(vehicles);

        getAllocations(preference);

        findMatchingBookings(preference);
    }

    /**
     * This function should get all matching allocations for
     * current city and for all which the destination city matches
     * to preference city
     */
    function getAllocations(preference) {
        var queries = [
            {
                field: 'actual_billing.status',
                value: 'pending',
            },
            {
                field: 'variant',
                value: preference.variant,
            },
            {
                field: 'color',
                value: preference.color,
            },
            {
                field: 'status',
                operator: '!=',
                value:'removed'
            },
        ];

        if (preference.city === 'all') {
            Requests.get(queries).then((result) => {
                setRequests(result.requests);
            });
        } else {
            //
            var q = [].concat(queries, [
                {
                    field: 'dealerCode',
                    value: cityKey[city],
                },
            ]);

            // Requests.get(q).then((current) => {
            // queries.push({
            //     field: 'destination_city',
            //     value: preference.city,
            // });

            Requests.get(queries).then((result) => {

                var all = [].concat(...result.requests);

                setRequests(all);
            });
            // });
        }
    }
    // Get booking status from status master
    async function getBookingStatus() {
        let queries = [
            {
                field: 'mode',
                value: 'BOOKING',
            },
        ];
        const result = await StatusMasters.get(queries);

        result.status_masters.forEach((element) => {
            status.push(element.name);
        });
    }


    /**
     *
     * Find matching bookings for which billing is pending
     *
     * @param {*} preference
     */
    function findMatchingBookings(preference) {
        // This block is not valid right now
        if (user.role === 'kec') {
            var queries = [
                {
                    field: 'Consultant Name',
                    value: user.dms['Consultant Name'],
                },
                {
                    field: 'dealerCode',
                    value: cityKey[city],
                },
            ];

            Bookings.get(queries).then((result) => {
                setMatching(
                    result.bookings.filter((booking) => {
                        return !booking.isCancelled;
                    })
                );
            });
        } else if (user.role === 'tl') {
            var queries = [
                {
                    field: 'Team Leader',
                    value: user.dms['Consultant Name'],
                },
                {
                    field: 'dealerCode',
                    value: cityKey[city],
                },
            ];

            Bookings.get(queries).then((result) => {
                setMatching(
                    result.bookings.filter((booking) => {
                        return !booking.isCancelled;
                    })
                );
            });
        } else {
            // If its admin or rm
            if (['admin', 'rm'].indexOf(user.role) !== -1) {
                if (preference.city === 'all') {
                    var queries = [
                        {
                            field: 'Model',
                            value: preference.model,
                        },
                        {
                            field: 'billing.status',
                            value: 'pending',
                        },
                    ];

                    Bookings.get(queries).then((result) => {
                        setMatching(
                            result.bookings.filter((booking) => {
                                return !booking.isCancelled;
                            })
                        );
                    });
                } else {
                    var queries = [
                        {
                            field: 'Model',
                            value: preference.model,
                        },
                        {
                            field: 'billing.status',
                            value: 'pending',
                        },
                        {
                            field: 'dealerCode',
                            value: cityKey[city],
                        },
                    ];

                    Bookings.get(queries).then((result) => {
                        setMatching(
                            result.bookings.filter((booking) => {
                                return !booking.isCancelled;
                            })
                        );
                    });
                }
            } else {
                var queries = [
                    {
                        field: 'Model',
                        value: preference.model,
                    },
                    {
                        field: 'billing.status',
                        value: 'pending',
                    },
                ];

                if (user.locations.length === 1) {
                    let city = user.locations[0];

                    queries.push({
                        field: 'dealerCode',
                        value: cityKey[city],
                    });
                }

                Bookings.get(queries).then((result) => {
                    setMatching(
                        result.bookings.filter((booking) => {
                            return !booking.isCancelled;
                        })
                    );
                });
            }
        }
    }

    return (
        <div className="preference-detail">
            <div className="page-header">
                <div>
                    <Title level={3}>Manage Allocation</Title>

                    <p className="page-desc">{loader ? '. Loading data' : ``}</p>
                </div>

                <div>
                    <Button onClick={loadPageData} type="secondary" size={'small'}>
                        Refresh
                    </Button>
                </div>
            </div>

            <div className="page-content">
                {/* Vehicle Card */}
                <div className="vehicle-thumbnail">
                    <div className="thumbnail">
                        <div className="vehicle-image-wrapper">
                            <img src={images[preference['model']]} alt="vehicle-img" />
                        </div>

                        <Title level={5}>
                            {preference['variant']} <i>{preference['color']}</i>
                        </Title>

                        <div gutter={0} className="detail-wrapper">
                            <Tag color="purple">{preference['city']}</Tag>
                        </div>
                    </div>

                    <Divider />

                    <Title level={4}>Vehicles</Title>

                    <p className="size-hint">{loader ? '. Loading data' : `${Object.keys(vehicle).length} records`}</p>

                    {loader ? (
                        <PlaceHolder type="report" />
                    ) : (
                        <List
                            size="small"
                            dataSource={Object.keys(vehicle)}
                            renderItem={(car) => (
                                <VehicleCard
                                    callback={loadPageData}
                                    city={match.params.city}
                                    requests={requests}
                                    access_unblocking={access_unblocking}
                                    vehicle={vehicle[car]}
                                // block={block}
                                />
                            )}
                        />
                    )}
                </div>

                <div className="right-content">
                    {loader ? (
                        <PlaceHolder type="report" />
                    ) : (
                        <Fragment>
                            <Tabs onChange={() => { }}>
                                <TabPane tab="Pre Allocation Requests" key="2">
                                    <List
                                        // header={<h4>Pre Allocation Requests</h4>}
                                        className="requests-list"
                                        itemLayout="horizontal"
                                        dataSource={requests}
                                        renderItem={(request, key) => (
                                            <RequestCard
                                                callback={loadPageData}
                                                bookings={matching}
                                                index={key}
                                                vehicles={vehicle}
                                                city={city}
                                                key={key}
                                                request={request}
                                                reqaccess_unblocking={access_unblocking}
                                            />
                                        )}
                                    />
                                </TabPane>

                                {/* <TabPane tab="Vehicles" key="1"></TabPane> */}
                            </Tabs>

                            <MatchingBookings matching={matching} preference={preference} callback={loadPageData} status={status} />
                        </Fragment>
                    )}
                </div>
            </div>
        </div>
    );
}

/**
 *
 * Section handles the matching bookings , filtering it according to conditions
 *
 */
function MatchingBookings({ city, requests, matching, preference, callback, status }) {
    const [filter, setFilter] = useState(status);

    let similarBookings = [];

    let similarVariant = [];

    let similarColor = [];

    let similarModel = [];

    matching
        .sort((a, b) => {
            if (a.current) {
                if (['CRITICAL CASE', 'URGENT'].indexOf(a.current.currentStatus) !== -1) {
                    return -1;
                }
                if (['CRITICAL CASE', 'URGENT'].indexOf(a.current.currentStatus) == -1) {
                    return 1;
                }
            } else {
                return 1;
            }
            return 0;
        })
        .forEach((booking) => {
            if (!booking.requestId) {
                if (booking && booking.current && booking.current.currentStatus) {
                    if (filter.indexOf(booking.current.currentStatus) !== -1) {
                        addBooking(booking, preference);
                    }
                } else {
                    addBooking(booking, preference);
                }
            }
        });

    function addBooking(booking, preference) {
        // To find similar bookings
        if (booking['Variant'] === preference.variant && booking['Color'] === preference.color) {
            similarBookings.push(booking);
        }

        // To find similar Variant
        if (booking['Variant'] === preference.variant) {
            similarVariant.push(booking);
        }

        // To find similar Color
        if (booking['Color'] === preference.color) {
            similarColor.push(booking);
        }

        // All bookings
        similarModel.push(booking);
    }

    return (
        <>
            <Title level={3} className="tab-header">
                Matching Bookings
            </Title>

            {/* <Tabs onChange={() => { }} tabBarExtraContent={handle}> */}
            <Tabs onChange={() => { }}>
                <TabPane tab={`Similar (${similarBookings.length})`} key="1">
                    <List
                        // header={<h4>Pre Allocation Requests</h4>}
                        className="requests-list"
                        itemLayout="horizontal"
                        dataSource={similarBookings}
                        renderItem={(item, index) => (
                            <BookingCard
                                key={index}
                                index={index}
                                preference={preference}
                                booking={item}
                                callback={(bookingNo, requestId) => {
                                    callback();
                                }}
                            />
                        )}
                    />
                </TabPane>

                <TabPane tab={`By Variant (${similarVariant.length})`} key="2">
                    <List
                        // header={<h4>Pre Allocation Requests</h4>}
                        className="requests-list"
                        itemLayout="horizontal"
                        dataSource={similarVariant}
                        renderItem={(item, index) => (
                            <BookingCard
                                key={index}
                                index={index}
                                preference={preference}
                                booking={item}
                                callback={(bookingNo, requestId) => {
                                    callback();
                                }}
                            />
                        )}
                    />
                </TabPane>

                <TabPane tab={`By Colour (${similarColor.length})`} key="3">
                    <List
                        // header={<h4>Pre Allocation Requests</h4>}
                        className="requests-list"
                        itemLayout="horizontal"
                        // dataSource={Object.keys(matching).filter((key) => !matching[key].requestId)}
                        dataSource={similarColor}
                        renderItem={(item, index) => (
                            <BookingCard
                                key={index}
                                index={index}
                                preference={preference}
                                booking={item}
                                callback={(bookingNo, requestId) => {
                                    callback();
                                }}
                            />
                        )}
                    />
                </TabPane>

                <TabPane tab={`By Model (${similarModel.length})`} key="4">
                    <List
                        // header={<h4>Pre Allocation Requests</h4>}
                        className="requests-list"
                        itemLayout="horizontal"
                        // dataSource={Object.keys(matching).filter((key) => !matching[key].requestId)}
                        dataSource={similarModel}
                        renderItem={(item, index) => (
                            <BookingCard
                                key={index}
                                index={index}
                                preference={preference}
                                booking={item}
                                callback={(bookingNo, requestId) => {
                                    callback();
                                }}
                            />
                        )}
                    />
                </TabPane>
            </Tabs>
        </>
    );
}
