/**
*
* @author Nihala Dilshi
*/

import React, { useEffect, useState, Fragment, useContext } from 'react';

import { Tag, Button, Card, Form, message } from 'antd';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import moment from 'moment';

import { Enquirys, StatusLogs, StatusMasters } from '../../../../models';

import { Typography } from 'antd';

import './enquiry-detail.scss';

import FollowUpStatus from '../follow_up_status/follow_up_status';
import { GlobalContext } from '../../../../Store';

const { Title, Text } = Typography;

export default function EnquiryDetail({ match }) {
    const [enquiry, setEnquiry] = useState({ proofs: {} });
    const { user } = useContext(GlobalContext);


    const { params } = match;

    const { id } = params;
    const [loader, setLoader] = useState(true);
    const [statusLogs, setStatusLogs] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        loadData();
    }, []);

    const [form] = Form.useForm();

    function loadData() {
        setLoader(true);

        Enquirys.getRecord(id).then((result) => {

            setEnquiry({ proof: {}, ...result, comments: [] });
        });
        loadDocument()

        setLoader(false);
    }

    /**
    * On submit of form
    *
    * @param {*} values
    */
    function onSubmit(values) {
        setLoading(true)
        if (values.nextDate) {
            values = {
                ...values,
                next_follow_up_date: moment(values.nextDate).startOf('day').valueOf(),
            }
            delete values.nextDate;
        }
        let queries = [
            {
                field: 'mode',
                value: 'follow_up_status',
            },
            {
                field: 'description',
                value: values.follow_up_status,
            },
        ];

        StatusMasters.get(queries).then(async (result) => {
            let data = result.status_masters[0]

            if (data) {
                values = {
                    ...values,
                    enquiry_status: data.category,
                    Type: data.priority,
                }
                // create record in StatusLogs & update in Enquirys
                await Enquirys.update(enquiry['Enquiry No'], values).then(async () => {
                    values = {
                        ...values,
                        status_id: data.id,
                        reference_number: enquiry.id
                    }
                    StatusLogs.add({ ...values }, { hideAlert: true }).then(async () => {
                        form.resetFields();
                        setLoading(false)
                        loadData();

                    })
                })
            }
        })

    }

    /*
    *Load the Document Logs record
    */
    function loadDocument() {
        setLoader(true);
        var queries = [
            {
                field: 'reference_number',
                value: id,
            },
        ];
        StatusLogs.get(queries).then((result) => {

            setStatusLogs([result.status_logs]);
        });
    }
    // sort by created_at
    const flattenedLogs = statusLogs.flat().sort((a, b) => moment(b.created_at, "DD/MM/YYYY HH:mm") - moment(a.created_at, "DD/MM/YYYY HH:mm"));

    return (
        <div className="booking-detail listing enquiry-detail">
            <div className="page-header">
                <Title level={3}>
                    Enquiry <i>{enquiry.id}</i>
                </Title>

            </div>

            {loader ? (
                <PlaceHolder type="report" />
            ) : (
                <Fragment>
                    <div className="page-content">
                        <div className="split-container">
                            <Card>
                                <div className="left">
                                    <div className="booking-info card card-shadow" >
                                        <h2 className="title amount">{enquiry['Name of the Customer']}</h2>
                                        <h3 className="title">{enquiry['Contact Number']} <a className="card-action" href={`tel:${enquiry['Contact Number']}`} key="list-loadmore-edit">Call</a></h3>
                                        <h3>{enquiry['Enquiry Status']}</h3>
                                        <Tag color="magenta">{enquiry['enquiry_status']}</Tag>
                                    </div>
                                    {user.role === 'kec' && enquiry['enquiry_status'] != 'LOST' ? (

                                        <div className="booking-info card card-shadow" >

                                            <Form form={form} onFinish={onSubmit} layout="vertical">
                                                <Form.Item
                                                    label="Status"
                                                    name="follow_up_status"
                                                    rules={[{ required: true, message: 'Please select a status!' }]}
                                                >
                                                    <FollowUpStatus callback={(val) => { }} content={enquiry} form={form} />

                                                </Form.Item>

                                                <Form.Item>
                                                    <Button loading={loading} type="primary" htmlType="submit">
                                                        Submit
                                                    </Button>
                                                </Form.Item>
                                            </Form>
                                        </div>
                                    ) : null}
                                </div>
                            </Card>

                            <Card>
                                <div className="right">
                                    {flattenedLogs.map((log, index) => (
                                        <Card className='enquiry-card card card-shadow'>
                                            <div key={index}>
                                                <h3><span className=""><strong>Enquiry Follow Up Status:</strong></span> {log.follow_up_status}</h3>
                                                <p className=''>Follow Up Date: {moment(log.created_at, "DD/MM/YYYY HH:mm").format("DD/MM/YYYY")}</p>
                                                <p className=''>Follow Up By: {log.created_by_name}</p>
                                                <p className=''>Status: {log.enquiry_status}</p>
                                                <p className=''>Type: {log.Type}</p>
                                                <p className=''>Remarks: {log.remarks}</p>
                                                {index === flattenedLogs.length - 1 && (
                                                    <p className=''>Total Enquiry Calls: {flattenedLogs.length}</p>
                                                )}
                                            </div>
                                        </Card>
                                    ))}
                                </div>
                            </Card>
                        </div>
                    </div>
                </Fragment>
            )}

        </div>
    );
}



