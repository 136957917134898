/**
 * Enquiry
 *
 * @author Nihala Dilshi
 */
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ReloadOutlined } from '@ant-design/icons';
import { Button, Space, Table, Switch } from 'antd';
import { Employees } from '../../../../models';
import moment from 'moment';
import { ExportReactCSV } from '../../resources/generic/generic-list/ExportReactCSV';
import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';
import { OrderedListOutlined, PicCenterOutlined } from '@ant-design/icons';
import { GlobalContext } from '../../../../Store';

export function EnquiryCard({ record, user, step }) {
    const { isMobile } = useContext(GlobalContext);

    const [filterChoices, setFilterChoices] = useState([]);

    const [result, setResults] = useState([]);

    const [filteredData, setFilteredData] = useState([]);

    const [loading, setLoading] = useState(true);

    const [view, setView] = useState(isMobile);

    const [card, setCard] = useState();


    const [expRecord, setExptRecords] = useState();



    useEffect(() => {
        getEmployyeMatch(record);
    }, [record]);

    /*
    * for download
    */
    useEffect(() => {
        var exporData = getExportData(filteredData, columns);
        setExptRecords(exporData);
    }, [filteredData]);


    function refresh() {
        getEmployyeMatch(record)
    }

    function changeView(result) {
        setCard(result);
    }
    /**
    * Get data of the kec to which booking is asssigned to store consultnat_id and teamleader_id
    * @param {*} employee
    * @returns
    */

    function getEmployyeMatch(record) {
        setLoading(true);

        Employees.getKECandTeamleader(record).then(async (res) => {
            setLoading(false);
            FiteringValue(res);
            setResults(res);
            setFilteredData(res); // Set initial filtered data to the entire result set
        })
    }


    function getExportData(data, columns) {
        return data.map((record, index) => {
            var content = {
                '#': index + 1,
            };
            columns.forEach((column) => {
                if (column.title === '#') {
                    // Skip index column
                } else if (column.exportDefinition) {
                    content[column.title] = column.exportDefinition(record, index);
                } else {
                    content[column.title] = record[column.dataIndex];
                }
            });
            return content;
        });
    }

    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            render: (value, item, index) => index + 1,
            disableExport: true,
        },
        {
            dataIndex: 'Enquiry No.',
            key: 'input',
            title: 'Enquiry No.',
        },
        {
            width: 200,
            dataIndex: 'Name of the Customer',
            key: 'input',
            title: 'Name of the Customer',
        },
        {
            title: 'KEC',
            dataIndex: 'consultant',
            key: 'consultant',
            width: 150,
            filters: filterChoices && filterChoices[0],
            onFilter: (value, record) =>
                record['consultant'] ? record['consultant'].indexOf(value) === 0 : null,
            filterDropdownVisible: user.role == 'kec' ? false : null,
        },
        {
            title: 'Team Leader',
            dataIndex: 'teamleader',
            key: 'teamleader',
            filters: filterChoices && filterChoices[1],
            onFilter: (value, record) =>
                record['teamleader'] ? record['teamleader'].indexOf(value) === 0 : null,
            filterDropdownVisible: user.role == 'tl' ? false : null,
            width: 150,
        },
        {
            width: 150,
            title: 'Enquiry Date',
            key: 'enquiry_date',
            render: (record) => {
                if (record.enquiry_date) {
                    return moment.tz(record.enquiry_date, 'Asia/Calcutta').format('DD/MM/YYYY');
                }
            },
            exportDefinition: (record) => {
                if (record.enquiry_date) {
                    return moment.tz(record.enquiry_date, 'Asia/Calcutta').format('DD/MM/YYYY');
                }
            },
        },
        {
            dataIndex: 'Interested in Exchange(Y/N)',
            key: 'input',
            title: 'Interested in Exchange(Y/N)',
        },
        {
            dataIndex: 'Source',
            key: 'input',
            title: 'Source',
        },
        {
            width: 300,
            dataIndex: 'follow_up_status',
            key: 'input',
            title: 'Follow Up Status',
        },
        {
            title: '',
            key: 'action',
            render: (text, record) => {
                var id = text['Dealer Code'] + text['Enquiry No.'];
                return (
                    <Space size="middle">
                        <Link to={`${step.redirect_to}/${record.City}/${id}`}>View</Link>
                    </Space>
                );
            },
        },
    ];

    const FiteringValue = (data) => {
        let arrKEC1 = [];
        let arrTL1 = [];

        data.forEach((ele) => {
            if (ele['consultant'] && !arrKEC1.some((item) => item.value === ele['consultant'])) {
                arrKEC1.push({ text: ele['consultant'], value: ele['consultant'] });
            }
            if (ele['teamleader'] && !arrTL1.some((item) => item.value === ele['teamleader'])) {
                arrTL1.push({ text: ele['teamleader'], value: ele['teamleader'] });
            }
        });

        let body = [arrKEC1, arrTL1];
        setFilterChoices(body);
    };

    const handleTableChange = (pagination, filters, sorter) => {
        const filtered = result.filter((item) => {
            // Implement your filtering logic based on the filters object
            let match = true;
            if (filters.consultant) {
                match = match && filters.consultant.includes(item.consultant);
            }
            if (filters.teamleader) {
                match = match && filters.teamleader.includes(item.teamleader);
            }
            return match;
        });
        setFilteredData(filtered);
    };

    return (
        <div>
            <div className="list-header">
                <div className="left">
                    <p>
                        {loading ? ('Loading data') : (<>{`Found ${filteredData.length} results`}</>)}
                    </p>

                    {/* Conditional rendering of the export button */}
                    {expRecord && <ExportReactCSV csvData={expRecord} />}

                    <Button onClick={refresh} type="secondary" size={'small'}>
                        <ReloadOutlined />
                    </Button>

                    <Switch defaultChecked={card} onChange={changeView} checked={card} />
                </div>
            </div>


            {/* Table component to display the data */}
            {loading ? (
                <PlaceHolder type="listing" />
            ) : (
                <>
                    {/* For card view  */}
               
                        { card ? (
                            <CardList data={filteredData} step={step} />
                        ) : (
                            <Table
                                loading={loading}
                                scroll={{ x: view ? true : undefined }} // Apply horizontal scrolling only if `view` is true
                                size="small"
                                rowKey={(record) => record.index}
                                dataSource={filteredData} // Use filtered data
                                columns={columns}
                                pagination={false}
                                onChange={handleTableChange} // Handle table change
                            />)
                    }
                </>

            )}
        </div>
    );
}

function CardList({ data, step }) {
    return data.map((report, index) => {
        return <Card report={report} step={step} index={index} key={index} />;
    });
}

/*
* EnquiryCard displaying
*/
function Card({ report = {}, step, index }) {

    var id = report['Dealer Code'] + report['Enquiry No.'];


    return (
        <Link className="report-item" to={`/${step.redirect_to}/${report.City}/${id}`}>
            <div className="card">
                <h2 className="title amount">{report['Name of the Customer']}</h2>
                <h3 className="title">{report['Contact Number']}</h3>
                <h3 className="title">Enquiry Date  : {moment.tz(report.enquiry_date, 'Asia/Calcutta').format('DD/MM/YYYY')}</h3>
                <h3 className="title">KEC  : {report['Consultant Name']}</h3>
                <h3 className="title">Teamleader :  {report.teamleader}</h3>
            </div>
        </Link>
    );
}