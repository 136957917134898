/**
 * Enquirys Dashboard Component
 *
 * @description Component to show Enquiry status
 * @author Nihala Dilshi
 */

import React, { useState, useEffect } from 'react';

import { Table, Card } from 'antd';

import { dealerCodeKey } from '../../../global-config';

import { Branches, Enquirys, StatusMasters } from '../../../../models';

var groupByNested = function (xs = [], key) {
    return xs.reduce(function (rv, x) {
        const keys = key.split('.');
        let value = x;
        for (let k of keys) {
            value = value[k];
            if (value === undefined) break;
        }
        const keyValue = value ? value : 'NoStatus';
        (rv[keyValue] = rv[keyValue] || []).push(x);
        return rv;
    }, {});
};


var groupBy = function (xs = [], key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};


/**
 *
 * Component for  Enquiry
 *
 * @param {*} param0
 */
export default function EnquiryDashboard({ }) {
    const [summary, setSummary] = useState([]);

    const [content, setContent] = useState({ enquiries: [] });

    const [dealerCode, setDealerCode] = useState([])

    const [statusName, setStatusName] = useState([])


    useEffect(() => {
        getEnquirysStatus();
        getEnquiry();
    }, []);


    /*
    *Get status from status master
    */
    async function getEnquirysStatus() {
        let queries = [
            {
                field: 'mode',
                value: 'follow_up_status',
            }
        ];
        const result = await StatusMasters.get(queries);
        result.status_masters.forEach((element) => {
            if (element.priority && !statusName.includes(element.priority)) {
                statusName.push(element.priority);
            }
        });
    }
    // state for status options

    const generateColumns = (dealerCodeKey) => {
        // Initialize the columns array with the static 'Code' column
        const columns = [
            {
                title: 'ENQUIRY STATUS',
                dataIndex: 'model',
            }
        ];

        // Iterate over the keys of the dealerKeys object to create dynamic columns
        for (const [key, value] of Object.entries(dealerCodeKey)) {
            columns.push({
                title: key.replace('KL', ''), // Extracting the number part of the key for the title
                className: value, // Converting the name  for the className
                render: (entry) => entry[key].mtd
            });
        }

        // Add the static 'Total' column at the end
        columns.push({
            className: 'total',
            title: 'TOTAL',
            render: (entry) => entry.all.mtd,
        });


        return columns;
    };

    // Generate the columns dynamically
    const columns = generateColumns(dealerCodeKey);



    /**
     *
     * Component for Enquiry
     *
     * @param {*} param0
     */

    async function getEnquiry() {

        var queries = [
            {
                field: 'enquiry_status',
                operator: 'in',
                value: ['LIVE', 'LOST', 'SUCCESS'],
            },
        ];

        Enquirys.getAll(queries, 'enquiries').then((result) => {
            const filteredEnquirys = result.all
            // .filter((enquiry) => enquiry.Type)

            setContent({ enquiries: filteredEnquirys });
            Branches.getAllBranches().then(async (res) => {
                //  all dealerCode
                setDealerCode(res)

                generateEnquiryDashboard(filteredEnquirys, res)
                    .then((arr) => {
                        setSummary(arr);
                    })
                    .catch((error) => {
                        console.error('Error generating enquiry dashboard:', error);
                    });
            })

        });
    }

    /**
     *
     * Component for generateEnquiryDashboard
     *
     * @param {*} param0
     */

    async function generateEnquiryDashboard(enquiries, branches) {

        var arr = [];

        // grouping enquiry have current status 
        const updatedData = enquiries.map(obj => {

            // Ashique - As i understand , the wrong status was considered here 
            // obj.status = obj.enquiry_status ? obj.enquiry_status : 'NoStatus';

            obj.status = obj.Type ? obj.Type.toUpperCase() : 'NoStatus';

            return obj;
        });

        const status = groupByNested(updatedData, 'status');

        console.log(status, statusName);

        statusName.forEach(async (model) => {

            var towns = groupBy(status[model], 'Dealer Code');

            let town = {}

            await branches.forEach((code) => {

                // Initialize each dealer code property in the town object with default ftd and mtd values
                // Here, 'ftd' stands for 'First Time Deposit' and 'mtd' stands for 'Monthly Total Deposit'
                town[code] = { mtd: 0 };
                // The 'model' property indicates the car model associated with the town
                town['model'] = model;

            });

            branches.forEach((code) => {
                var customers = towns[code];

                if (customers && customers.length) {
                    town[code] = {
                        mtd: customers.length,
                    };
                } else {
                    town[code] = { mtd: 0 };
                }
            });

            if (status[model]) {
                console.log('statusoT', status[model])

                town.all = {
                    mtd: status[model].length,
                };
            }

            arr.push(town);
        });

        return arr;
    }

    return (
        <>
            <div>
                <>
                    {content['enquiries'].length ? (
                        <Card title={`ENQUIRY STATUS`} style={{ width: '100%', textAlign: 'center' }}>
                            <Table
                                size="small"
                                scroll={{ x: true }}
                                rowKey={(record) => record.id}
                                dataSource={summary}
                                columns={columns}
                                pagination={false}
                                summary={(pageData) => {

                                    let summary = {};
                                    // Loop through each dealer code in the dealerCode array
                                    dealerCode.forEach((code) => {
                                        // Initialize each dealer code property in the summary object with default  mtd values
                                        summary[code] = { mtd: 0 };
                                    });
                                    pageData.forEach((entry) => {
                                        dealerCode.forEach((code) => {
                                            if (entry[code]) {
                                                summary[code].mtd += entry[code].mtd;
                                            }
                                        });
                                    });

                                    return (
                                        <>
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell>
                                                    <strong>Total</strong>
                                                </Table.Summary.Cell>

                                                {dealerCode.map((code) => {
                                                    return (
                                                        <>
                                                            <Table.Summary.Cell>
                                                                <strong>{summary[code].mtd}</strong>
                                                            </Table.Summary.Cell>
                                                        </>
                                                    );
                                                })}
                                            </Table.Summary.Row>
                                        </>
                                    );
                                }}
                            ></Table>

                        </Card>
                    ) : null}
                </>
            </div>
        </>
    );

}



