import React, { useEffect, useState, Fragment, useContext } from 'react';

import { Link } from 'react-router-dom';

import { Tag, Tabs, Badge, Button, Row, Steps, Card, Alert, Select, message, Popconfirm, Dropdown, Menu, Modal } from 'antd';

import SELECT from 'react-select';

import { DatePicker, Form, Input, InputNumber, Radio } from 'antd';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import { GlobalContext } from '../../../../Store';

import FirebaseUtils from '../../../../utils/firebase.utils';

import CommentBlock from './../../../common/components/comment-block/comment-block';

import BookingInfo from './../../../../components/booking-info/booking-info';

import MatchingReceipts from '../../../../components/matching-receipts/matching-receipts';

import ImageWrapper from './../../../../components/image-wrapper/image-wrapper';

import moment from 'moment';

import { CheckCircleOutlined, UserOutlined, EllipsisOutlined } from '@ant-design/icons';

import { Employees, Designations, Bookings, Requests, Enquirys, StatusMasters } from '../../../../models';

import './booking-detail.scss';

import { Typography } from 'antd';

import FileUpload from './../../../../components/file-upload/file-upload';

import AccessoryChoices from './../../components/accessory-choices/accessory-choices';

import Bills from './../../components/bills/bills';
import { cityKeys, dealerCodeKey, dealerKeys } from '../../../global-config';

const { Title, Text } = Typography;

const { TextArea } = Input;

const { Option } = Select;

const dateFormat = 'DD/MM/YYYY';

var badgeColor = {
    Allocated: 'error',
    'In transit': 'warning',
    'Free Stock': 'success',
};

var cityCode = dealerCodeKey;

var cityName = dealerKeys;

var cityKey = cityKeys;

const { TabPane } = Tabs;

const { Step } = Steps;

const layout = {
    labelCol: {
        xs: { span: 8 },
        sm: { span: 8 },
        lg: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 8 },
        sm: { span: 8 },
        lg: { span: 8 },
    },
};

// Maintaining each flag for each process
let defaultStats = {
    accounts: {
        status: 'pending',
    },

    allocation: {
        status: 'pending',
    },

    finance: {
        status: 'pending',
        process: 'incomplete',
    },
    // insurance: {
    //     status: 'pending',
    //     process: 'incomplete'

    // },
    accessories: {
        status: 'pending',
        process: 'incomplete',
    },
    warranty: {
        status: 'pending',
        process: 'incomplete',
    },
    billing: {
        status: 'pending',
    },

    actual_billing: {
        status: 'pending',
    },
};

var dealer = dealerCodeKey;

export default function BookingDetail({ match }) {
    const { user } = useContext(GlobalContext);

    const [booking, setBooking] = useState({ proofs: {} });

    const [request, setRequest] = useState({ created_by: {} });

    const [vehicle, setVehicle] = useState({});

    const [invoice, setInvoice] = useState({});

    const [kecvisible, setKecVisible] = useState(false);

    const [matching, setMatching] = useState([]);

    const [kecInitial, setKecInitial] = useState({});

    const [status, setStatus] = useState([]);

    const [cancellationStatus, setCancellationStatus] = useState([]);

    const { params } = match;

    const { id } = params;

    const [loader, setLoader] = useState(true);

    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);

    const [btnloading, setBtnLoading] = useState(false);

    const [kec, setKec] = useState([]);

    const [findKec, setFindKec] = useState([]);

    const [visible, setVisible] = useState(false);

    var city = user.locations[0];

    useEffect(() => {
        getBookingStatus();
        getCancellationStatus();
        loadData();
    }, []);

    function loadData() {
        setLoader(true);

        FirebaseUtils.getBooking(id).then((result) => {
            var booking = result.data();

            // console.log(booking);

            //Get details of the current kec and teamleader

            getEmployee(booking);

            setBooking({ proof: {}, ...booking, comments: [] });

            setLoader(false);

            if (booking.requestId) {
                getRequest(booking);
            } else {
                findMatchingVehicles(booking);
            }

            if (booking.invoiced_by) {
                getInvoice(booking.invoiced_by);
            }
        });
    }

    // Get booking status from status master
    async function getBookingStatus() {
        let queries = [
            {
                field: 'mode',
                value: 'BOOKING',
            },
        ];

        const result = await StatusMasters.get(queries);
        setStatus(result.status_masters || []);
    }

    // Get status_master_id from status master

    /***
     * function to get status_master_id from status master
     * To Add Status for cancellation process
     */
    async function getCancellationStatus() {
        let queries = [
            {
                field: 'mode',
                value: 'CANCELLATION',
            },
        ];
        const result = await StatusMasters.get(queries);
        setCancellationStatus(result.status_masters || []);
    }

    /***
     *
     * function to get current kec details from employee table
     * Here all kecs are loaded from employees and given in select for kec change
     */

    async function getEmployee(value) {
        //Get all employees in the current location

        var queries = [
            {
                field: 'branch',
                value: cityName[value.dealerCode],
            },
        ];

        return await Employees.get(queries).then((result) => {
            console.log(result);

            var query = [
                {
                    field: 'designation',
                    value: 'KEC',
                },
            ];

            return Designations.get(query).then((res) => {
                var kecList = result.employees.filter((ele) => ele.designation === res.designations[0].id);

                setFindKec(kecList);

                //Preparing data for react-select
                let b = Object.keys(kecList).map((employee) => {
                    return { label: kecList[employee].fname + ' ' + kecList[employee].lname, value: kecList[employee].id };
                });

                setKec(b);
                //Get the current kec from the consultant_id present in booking
                //the consultant_id is matched in employees to get the current cinsultant details
                var k = result.employees.find((ele) => ele.id === value.consultant_id);

                setKecInitial({ current_kec: k.fname + ' ' + k.lname }); //Setting initial values in the form for kec change

                return result.employees;
            });
        });
    }
    /**
     *
     * Find matching vehicles for request
     *
     * @param {*} request
     */
    function findMatchingVehicles(booking) {
        let queries = [
            {
                field: 'dealerCode',
                value: cityKey[city],
            },
        ];

        if (booking['Model'] && city) {
            FirebaseUtils.getPossibleVehicles(booking['Model'], queries).then((result) => {
                // console.log(result);

                setVehicle(result);

                setLoader(false);
            });
        } else {
            message.error('Failed due to an error');
        }
    }

    function getRequest(booking) {
        FirebaseUtils.getRequest(booking.requestId).then((result) => {
            var request = result;

            if (request) {
                setRequest({ ...request, comments: [] });

                setLoader(false);

                if (request.vinNo) {
                    getVehicle(request.vinNo);
                } else {
                    getMatchingVehicles(booking);
                }

                setLoader(false);
            } else {
                findMatchingVehicles(booking);

                // To set empty
                setRequest({ empty: true });
            }
        });
    }

    function getMatchingVehicles(booking) {
        var queries = [
            {
                field: 'Stock Status',
                operator: 'in',
                value: ['Free Stock', 'In transit'],
            },
            {
                field: 'Variant',
                value: booking['Variant'],
            },
            {
                field: 'Exterior Color Name',
                value: booking['Color'],
            },
        ];

        FirebaseUtils.getVehicles(city, queries).then((result) => {
            setVehicle(result.vehicles);
        });
    }

    function getVehicle(id) {
        FirebaseUtils.getVehicle(id).then((result) => {
            // console.log(result);
            // setAllocated(result);
            // #todo the booking object have to be removed from

            setInvoice(result.data());

            // console.log(result.data());
        });
    }

    function getInvoice(id) {
        FirebaseUtils.getListingRecord(id, 'sales').then((result) => {
            setInvoice(result.data());

            // console.log(result.data());

            // console.log(result);
            // setAllocated(result);
            // #todo the booking object have to be removed from
        });
    }

    function cancelRequest() {
        var index = request.id;
        let params = {
            status: 'removed',
        };
        FirebaseUtils.updateRequestGeneric(request.id, params, 'Request is Removed', 'removed', 'requests').then(
            () => {
                if (request.bookingNo) {
                    FirebaseUtils.removeRequest(request.bookingNo, false).then(() => {
                        loadData();

                        message.success('Request has been removed');
                    });
                }
            })
    }

    /**
     *
     * Allocate already existing booking to
     * a vehicle
     *
     * @param {*} param0
     */
    // function createRequest(vehicle = {}) {
    //     // if (invoice['Invoice No']) {
    //     // }

    //     FirebaseUtils.createRequest(city, booking, user, vehicle, invoice).then((result) => {
    //         FirebaseUtils.updateBooking(booking['Booking No'], true, result.id).then(() => {
    //             message.info('Request has been created');

    //             loadData();
    //         });
    //     });
    // }

    /**
     *
     * Allocate already existing booking to
     * the team leader
     * Team leader has to verify after which the finance process woudl
     * start
     *
     * @param {*} param0
     */
    function createRequest() {
        setLoading(true);

        let city = user.locations[0];

        // Find the city of the booking
        let bookingCity = dealer[booking['Dealer Code']];

        let customer = {
            customerName: booking['Name of the Customer'],
            phone: booking['Contact Number'],
            // place: booking["Ship To Add"] || booking["Bill To Add"],
            customerID: booking['Customer ID'],
        };

        if (booking['Bill To Add']) {
            customer = {
                ...customer,
                place: booking['Bill To Add'],
            };
        }

        let basic = {
            status: 'Request Created',
            model: booking['Model'],
            color: booking['Color'],
            city: city,
            dealerCode: booking['Dealer Code'],
            variant: booking['Variant'],
            kec: booking['Consultant Name'],
            teamLeader: booking['Team Leader'] || 'NA',
            bookingNo:
                booking['Booking No'].substring(0, 4) !== 'KL30' && booking['Booking No'].substring(0, 4) !== 'KL50'
                    ? booking['Dealer Code'] + booking['Booking No']
                    : booking['Booking No'],
            customer: customer,
            bookingId: booking['Booking No'],
            created_by: user.id,
            created_by_name: user.name,
            created_by_role: user.role,
        };

        if (booking['Ship To Add'] && !customer.place) {
            customer = {
                ...customer,
                place: booking['Ship To Add'],
            };
        }

        let newBooking = {
            ...basic,
            ...defaultStats,

            dealerCode: booking['Dealer Code'],

            verified: true,
            status: 'Verification Pending',
            kec: booking['Consultant Name'],
            teamLeader: booking['Team Leader'] || 'NA',
            deleted_at: null,
            deleted_by: null,

            // ...preference, // preference is considered here , not the booking choice .
            city: bookingCity, // override the city in the preference with the one in the booking
            ...{
                bookingNo:
                    booking['Booking No'].substring(0, 4) !== 'KL30' && booking['Booking No'].substring(0, 4) !== 'KL50'
                        ? booking['Dealer Code'] + booking['Booking No']
                        : booking['Booking No'],
            },
            ...{
                customer: customer,
                created_at: moment().format('DD/MM/YYYY HH:mm'),
                // expectedDate: booking['Committed Delivery Date']
            },
        };

        //Taking consultant_id and teamleader_id from booking to store in requests
        //when request is created

        if (booking['consultant_id']) {
            newBooking = {
                ...newBooking,
                consultant_id: booking['consultant_id'],
            };
        }

        if (booking['teamleader_id']) {
            newBooking = {
                ...newBooking,
                teamleader_id: booking['teamleader_id'],
            };
        }

        // If its for a different location , we have to add a field to identify that
        // if (city !== preference.city) {
        //     newBooking.destination_city = preference.city;
        // }

        if (booking['Committed Delivery Date']) {
            newBooking.expectedDate = booking['Committed Delivery Date'];
        }

        FirebaseUtils.addRequest(newBooking).then((result) => {
            FirebaseUtils.updateBooking(
                // bookingCity,
                booking['Booking No'],
                true,
                result.id
            ).then(() => {
                message.info('Request created');

                setLoading(false);

                loadData();
                // callback();
            });
        });
    }

    // const menu = (
    //     <Menu onClick={openBookingCancelModal}>
    //         <Menu.Item key="1" icon={<UserOutlined />}>
    //             Cancel Booking
    //         </Menu.Item>
    //     </Menu>
    // );

    function openBookingCancelModal(index) {
        setVisible(true);
    }

    /**
     * On edit model close
     */
    function closeModal() {
        setVisible(false);
    }

    /**
     * On edit model submit
     */
    async function submitRequest() {
        setLoading(true);
        await form
            .validateFields()
            .then(async (values) => {

                await form.resetFields();
                let creation = {
                    created_at: moment().format('DD/MM/YYYY HH:mm'),
                    created_date: moment().startOf('day').valueOf(),
                    created_time: moment().valueOf(),
                    deleted_at: null,
                    deleted_by: null,
                };
                let params = {
                    ...values,
                    ...creation,
                    bookingNo: values.bookingId,
                    dealerCode: cityKey[user.locations[0]],
                    created_by: user.id,
                    created_by_name: user.name,
                    type: 'booking',
                    status: 'Teamleader Approval Pending',
                    status_master_id: values.status_master_id,
                };
                FirebaseUtils.createCancelRequest(params).then(async(result) => {
                    // Update booking status  
                    await Bookings.updateModelStatus(values.bookingId,'CANCELLED')

                    let body = {
                        cancellation: {
                            id: result.id,
                            status: 'Teamleader Approval Pending',
                        },
                    };
                    FirebaseUtils.updateBookingGeneric(values.bookingId, body).then((result) => {
                        message.info('Booking cancel request created successfully.');
                        setVisible(false);
                        setLoading(false);
                        loadData();
                    });
                });
            
            })
            .catch((info) => {
                // console.log('Validate Failed:', info);
                setVisible(false);
                setLoading(false);
                // loadData();
            });
    }

    function onUpload(caption, attachments) {
        // setDisabled(false);
        form.setFieldsValue({ [caption]: attachments });
    }

    /**
     * function to change kec
     * Here the newly selected kec's consultnat_id and teamleader_id are updated in bookinhgs, requests and enquiries
     * @param {*} value
     */
    async function changeKec(value) {
        var selectChoice = value.selected_kec;

        setKecVisible(false);

        setBtnLoading(true);

        var selectedkec = findKec.find((ele) => ele.id === selectChoice.value);

        var app = FirebaseUtils.getApp();

        var batch = app.batch();

        var userRequest = {};

        Employees.changeKec(selectedkec, booking, user, userRequest, batch).then(async (result) => {
            await batch.commit();

            message.success(`KEC and Teamleader for ${booking['Booking No']} is updated successfully`);

            setBtnLoading(false);

            loadData();
        });
    }

    function assignKec() {
        setKecVisible(true);
        setBtnLoading(true);
    }

    let kec_name = '';

    if (user.dms && user.dms['Consultant Name']) {
        kec_name = user.dms['Consultant Name'];
    }

    return (
        <div className="booking-detail listing">
            <div className="page-header">
                <Title level={3}>
                    Booking <i>{booking['Booking No']}</i>
                </Title>
                <div>
                    <Button onClick={loadData} type="secondary" size={'small'}>
                        Refresh
                    </Button>
                    {/* {(booking['Consultant Name'] === kec_name && booking && !booking.cancellation) ||
                    (booking && booking.cancellation && booking.cancellation.status === 'Declined') ? (
                        <Dropdown size="small" overlay={menu}>
                            <EllipsisOutlined />
                        </Dropdown>
                    ) : null} */}
                </div>
            </div>

            {loader ? (
                <PlaceHolder type="report" />
            ) : (
                <Fragment>
                    <div className="page-content">
                        <div className="split-container">
                            <div className="left">
                                <BookingInfo booking={booking} />

                                {user.role === 'sm' || user.role == 'admin' || user.role == 'edp' ? (
                                    // {/**Only billing pending or delivery pending cases should have kec change option */}
                                    (booking['billing'] && booking['billing'].status === 'pending') ||
                                    (booking['delivery'] && booking['delivery'].status === 'pending') ? (
                                        <Button loading={btnloading} style={{ marginTop: '10px' }} type="secondary" onClick={() => assignKec()}>
                                            Assign booking to another KEC
                                        </Button>
                                    ) : null
                                ) : null}
                            </div>

                            <div className="right">
                                {booking.cancellation ? (
                                    <Card className="" style={{ marginBottom: '15px' }}>
                                        <Alert
                                            style={{ margin: '10px 0px' }}
                                            message={`Booking Cancellation.`}
                                            description={booking.cancellation.status}
                                            type="info"
                                        />
                                        <div>
                                            For Details :- {'  '}
                                            <Link to={`/${city}/cancellations/${booking.cancellation.id}`}>{booking.cancellation.id}</Link>
                                        </div>
                                    </Card>
                                ) : null}

                                {/*Kec change modal begins */}

                                <Modal
                                    visible={kecvisible}
                                    footer={null}
                                    className=""
                                    onCancel={() => {
                                        setKecVisible(false);
                                        setBtnLoading(false);
                                    }}
                                    destroyOnClose={true}
                                >
                                    <Form layout="vertical" onFinish={changeKec} initialValues={kecInitial}>
                                        <p>Are you sure you want to proceed?</p>
                                        <Form.Item name={'current_kec'} label="Current KEC">
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            name={'selected_kec'}
                                            label="Selected KEC"
                                            rules={[
                                                {
                                                    message: 'Please Select the KEC',
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            {/* <Select
                                                allowClear
                                                style={{ width: '100%' }}
                                                placeholder="Please select"

                                            >
                                                {kec.map((kec) => {
                                                    return (
                                                        <Option value={kec.id} key={kec.dms_name}>
                                                            {kec.fname} - {kec.branch}
                                                        </Option>
                                                        // <Option value={kec.userId} key={kec.email}>
                                                        //     {kec.name} - {kec.locations[0]}
                                                        // </Option>
                                                    );
                                                })}
                                            </Select> */}
                                            <SELECT style={{ width: '100%' }} options={kec} isClearable={true} />
                                        </Form.Item>
                                        <Form.Item>
                                            <Button htmlType="submit" type="primary">
                                                Submit
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </Modal>
                                {/*Kec change modal ends */}

                                {booking.requestId && !request.empty && !(request.status === 'removed') ? (
                                    <Card className="">
                                        <>
                                            <Tag icon={<CheckCircleOutlined />} color="success">
                                                Request Created
                                            </Tag>

                                            {user.role === 'kec' ? (
                                                <Link to={`/requests/${booking.requestId}`}>View Request</Link>
                                            ) : (
                                                <Link to={`/requests/${booking.requestId}`}>View Request</Link>
                                            )}
                                        </>

                                        {!request.empty &&
                                        booking.requestId &&
                                        // request.created_by &&
                                        // request.created_by.name === user.name &&
                                        request.allocation &&
                                        !(request.status === 'removed') &&
                                        !request.allocation.vinNo ? (
                                            <Popconfirm
                                                title="Are you sure to delete this task?"
                                                onConfirm={() => cancelRequest()}
                                                onCancel={() => {}}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <Button style={{ marginTop: '10px' }} size="small" type="secondary">
                                                    Cancel Request
                                                </Button>
                                            </Popconfirm>
                                        ) : null}

                                        {/* Cancel Booking Option */}
                                        {/* <Popconfirm
                                            title="Are you sure to delete this task?"
                                            onConfirm={() => cancelRequest()}
                                            onCancel={() => { }}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button style={{ marginTop: '10px' }} size="small" type="secondary">
                                                Cancel Request
                                            </Button>
                                        </Popconfirm> */}
                                    </Card>
                                ) : (
                                    <Card className="">
                                        {request.empty ? (
                                            <Alert
                                                style={{ margin: '10px 0px' }}
                                                message="Request has error"
                                                description={`We tried to fetch your request but we ran into some error. You might have to create a request again.`}
                                                type="error"
                                            />
                                        ) : (
                                            <>
                                                <Alert
                                                    style={{ margin: '10px 0px' }}
                                                    message="Request creation pending"
                                                    description={`Your next proceedings for your booking would start as soon as a request is created. `}
                                                    type="info"
                                                />

                                                {['admin', 'sm', 'rm'].indexOf(user.role !== -1) && booking.document_status === 'completed' ? (
                                                    <Popconfirm
                                                        title="Are you sure you want to create a request ?"
                                                        onConfirm={createRequest}
                                                        onCancel={() => {}}
                                                        okText="Yes"
                                                        cancelText="No"
                                                    >
                                                        <Button type="secondary">Create Request</Button>
                                                    </Popconfirm>
                                                ) : (
                                                    <Alert
                                                        message={`Please upload customer proof to create request`}
                                                        type="info"
                                                        showIcon
                                                        style={{ margin: '10px 0px' }}
                                                    />
                                                )}
                                            </>
                                        )}

                                        {((user.employee_id === booking.consultant_id || user.employee_id === booking.teamleader_id) &&
                                            booking &&
                                            !booking.cancellation) ||
                                        (booking && booking.cancellation && booking.cancellation.status === 'Declined') ? (
                                            <>
                                                <Popconfirm
                                                    title="Are you sure you want to cancel this booking ?"
                                                    onConfirm={openBookingCancelModal}
                                                    onCancel={() => {}}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    <Button type="secondary">Cancel Booking</Button>
                                                </Popconfirm>
                                            </>
                                        ) : null}

                                        {/* {(booking['Consultant Name'] === kec_name && booking && !booking.cancellation) ||
                                        (booking && booking.cancellation && booking.cancellation.status === 'Declined') ? (
                                            <Dropdown size="small" overlay={menu}>
                                                <EllipsisOutlined />
                                            </Dropdown>
                                        ) : null} */}
                                    </Card>
                                )}

                                {/* Invoice Card */}
                                {invoice && invoice['Invoice No'] ? (
                                    <Card className="">
                                        <Title level={3} className="tab-header">
                                            Invoice
                                        </Title>

                                        <>
                                            <Tag icon={<CheckCircleOutlined />} color="success">
                                                Done
                                            </Tag>

                                            <div gutter={0} className="detail-wrapper">
                                                <div className="detail-element">
                                                    <Row span={12}>
                                                        <span>Invoice Date</span>
                                                    </Row>
                                                    <Row span={12}>
                                                        <h3>{invoice['Invoice Date']}</h3>
                                                    </Row>
                                                </div>

                                                <div className="detail-element">
                                                    <Row span={12}>
                                                        <span>Invoice No</span>
                                                    </Row>
                                                    <Row span={12}>
                                                        <h3>{invoice['Invoice No']}</h3>
                                                    </Row>
                                                </div>

                                                <div className="detail-element">
                                                    <Row span={12}>
                                                        <span>Vin Number</span>
                                                    </Row>
                                                    <Row span={12}>
                                                        <h3>{invoice['Vin Number']}</h3>
                                                    </Row>
                                                </div>
                                            </div>
                                        </>
                                    </Card>
                                ) : null}
                                {/* Invoice Card Ends */}

                                {/* Current Status */}
                                {['admin', 'sm', 'rm', 'kec' ,'tl'].indexOf(user.role) !== -1 ? (
                                    <CurrentStatus booking={booking} id={id} city={city} callback={loadData} status={status} />
                                ) : null}
                                {/* Current Status Ends */}

                                {['kec', 'super', 'admin', 'sm', 'finance'].indexOf(user.role) !== -1 ? (
                                    <CustomerProof booking={booking} id={id} city={city} callback={loadData} />
                                ) : null}
                                {/* Proof Files Ends */}

                                {/* Accessory Choice Selection */}
                                {['kec', 'super', 'admin', 'sm', 'finance', 'accessories'].indexOf(user.role) !== -1 ? (
                                    <AccessoryChoices callback={loadData} isBooking={true} booking={booking} request={request} city={request.city} />
                                ) : null}
                                {/* Accessory Choice Selection Ends */}

                                {/* Bills Selection */}
                                {['kec', 'super', 'admin', 'sm', 'finance', 'accessories'].indexOf(user.role) !== -1 ? (
                                    <Bills callback={loadData} isBooking={true} booking={booking} request={request} />
                                ) : null}
                                {/* Bills Selection Ends */}

                                {/* Insurance Choices */}
                                {['kec', 'super', 'admin', 'sm', 'finance'].indexOf(user.role) !== -1 ? (
                                    <InsurancePreference id={id} city={city} isBooking={true} booking={booking} callback={loadData} />
                                ) : null}
                                {/* Insurance Choices Ends */}

                                {/* Warranty Choices */}
                                {['kec', 'super', 'admin', 'sm', 'finance'].indexOf(user.role) !== -1 ? (
                                    <WarrantyPreference id={id} city={city} isBooking={true} booking={booking} callback={loadData} />
                                ) : null}
                                {/* Warranty Choices Ends */}

                                {/* Receipts */}
                                {booking['Booking No'] ? (
                                    <MatchingReceipts
                                        callback={() => {}}
                                        userRequest={{
                                            city: cityCode[booking['Dealer Code']],
                                            bookingNo: booking['Booking No'],
                                        }}
                                    />
                                ) : null}
                                {/* Receipts Ends */}

                                <CommentBlock id={booking['Booking No']} />

                                {/* Show matching vehicles if no vehicle is allocated */}
                                {/* {
                                        !booking.requestId || request.empty ? <>


                                            <Title level={3} className="tab-header">
                                                Matching Vehicles
                                            </Title>

                                            <Tabs onChange={() => { }}>

                                                <TabPane tab={`Similar (${Object.keys(vehicle).filter((key) => (vehicle[key]['Variant'] === booking['Variant']) && (vehicle[key]['Exterior Color Name'] === booking['Color'])).length})`} key="0">

                                                    <List
                                                        size="small"
                                                        dataSource={Object.keys(vehicle).filter((key) => (vehicle[key]['Variant'] === booking['Variant']) && (vehicle[key]['Exterior Color Name'] === booking['Color']))}
                                                        renderItem={car => <VehicleCard
                                                            callback={(newBooking) => { afterBlock({ newBooking, vehicle, car }) }}
                                                            blockVehicle={() => { createRequest(vehicle[car]) }}
                                                            city={match.params.city}
                                                            vehicle={vehicle[car]}
                                                            unblock={unblock}


                                                        />}
                                                    />

                                                </TabPane>

                                                <TabPane tab={`By Variant (${Object.keys(vehicle).filter((key) => (vehicle[key]['Variant'] === booking['Variant'])).length})`} key="1">

                                                    <List
                                                        size="small"
                                                        dataSource={Object.keys(vehicle).filter((key) => (vehicle[key]['Variant'] === booking['Variant']))}
                                                        renderItem={car => <VehicleCard
                                                            callback={(newBooking) => { afterBlock({ newBooking, vehicle, car }) }}
                                                            blockVehicle={() => { createRequest(vehicle[car]) }}
                                                            city={match.params.city}
                                                            vehicle={vehicle[car]}
                                                            unblock={unblock}

                                                        />}
                                                    />

                                                </TabPane>

                                                <TabPane tab={`By Colour (${Object.keys(vehicle).filter((key) => (vehicle[key]['Exterior Color Name'] === booking['Color'])).length})`} key="2">

                                                    <List
                                                        size="small"
                                                        dataSource={Object.keys(vehicle).filter((key) => (vehicle[key]['Exterior Color Name'] === booking['Color']))}
                                                        renderItem={car => <VehicleCard
                                                            callback={(newBooking) => { afterBlock({ newBooking, vehicle, car }) }}
                                                            blockVehicle={() => { createRequest(vehicle[car]) }}
                                                            city={match.params.city}
                                                            vehicle={vehicle[car]}
                                                            unblock={unblock}

                                                        />}
                                                    />

                                                </TabPane>

                                                <TabPane tab={`By Model (${Object.keys(vehicle).length})`} key="3">

                                                    <List
                                                        size="small"
                                                        dataSource={Object.keys(vehicle)}
                                                        renderItem={car => <VehicleCard
                                                            callback={(newBooking) => { afterBlock({ newBooking, vehicle, car }) }}
                                                            blockVehicle={() => { createRequest(vehicle[car]) }}
                                                            city={match.params.city}
                                                            vehicle={vehicle[car]}
                                                            unblock={unblock}

                                                        />}
                                                    />

                                                </TabPane>
                                            </Tabs>

                                        </> : null
                                    } */}

                                {/* <CommentBlock id={booking.bookingNo} /> */}
                            </div>
                        </div>
                    </div>
                </Fragment>
            )}
            <Modal confirmLoading={loading} title="Cancel Booking" visible={visible} onOk={submitRequest} onCancel={closeModal}>
                <Form
                    form={form}
                    {...layout}
                    name="new-record"
                    layout="vertical"
                    onFieldsChange={(fields) => {}}
                    initialValues={{
                        phone: booking['Contact Number'],
                        bookingId: booking['Booking No'],
                        customerName: booking['Name of the Customer'],
                    }}
                >
                    <Form.Item name={'bookingId'} label="Booking ID" rules={[{ message: 'Enter Booking ID' }]}>
                        <Input disabled />
                    </Form.Item>
                    <Form.Item
                        name={'reason'}
                        label="Reason"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter reason',
                            },
                        ]}
                    >
                        <TextArea rows={4} />
                    </Form.Item>
                    {/* Add Status for cancellation process */}
                    <Form.Item name={'status_master_id'} label={'Cancellation Status'} rules={[{ required: true }]} style={{ width: '800px' }}>
                        <Select placeholder="Please enter the cancellation status">
                            {cancellationStatus.map((data) => (
                                <Option value={data.id}>{data.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name={'cancellation_request'}
                        label={'Cancellation Request'}
                        rules={[
                            {
                                required: true,
                                message: 'Please attach Cancel Request',
                            },
                        ]}
                    >
                        <FileUpload
                            callback={(attachments) => {
                                onUpload('cancellation_request', attachments);
                            }}
                            // onProgress={() => { setDisabled(true) }}
                        />
                    </Form.Item>
                    <Form.Item name={'customerName'} label="Customer Name" rules={[{ message: 'Enter Number' }]}>
                        <Input disabled />
                    </Form.Item>
                    <Form.Item name={'email'} label="Email" rules={[{ required: true, message: 'Please enter Email Address' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name={'phone'} label="Phone No." rules={[{ required: true, message: 'Enter Number' }]}>
                        <InputNumber />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}

/**
 *
 * Current Status
 *
 * @returns
 */
function CurrentStatus({ city, id, booking, callback, status }) {
    const { user } = useContext(GlobalContext);

    /**
     *
     *
     * @param {*} values
     */
    const onDataCollection = (values) => {
        let status_name = status.filter((record) => record.id === values.currentStatus);
        let params = {
            current: {
                currentStatus: status_name[0].name,
                currentStatus_id: status_name[0].id,
                expectedDate: values.expectedDate,
                remarks: values.remarks,
            },
        };

        if (values.expectedDate) {
            params.current['expectedDate'] = moment(values.expectedDate).format(dateFormat);
        }

        FirebaseUtils.updateBookingGeneric(id, params, 'Updated booking status', values.remarks, booking).then(() => {
            message.success('The booking status has been updated');

            callback();
        });
    };

    let initialValues = {
        ...booking.current,
    };

    if (booking.current && booking.current.expectedDate) {
        initialValues['expectedDate'] = moment(booking.current.expectedDate, dateFormat);
    }

    return (
        <div className="vehicle-card card">
            <Title level={3}>Booking Status</Title>

            <p>{booking.lastupdated_at}</p>

            <Form
                // form={form}
                {...layout}
                name="new-record"
                onFinish={onDataCollection}
                layout="vertical"
                initialValues={initialValues}
            >
                <Form.Item name={'currentStatus'} label={'Current Status'} rules={[{ required: true, message: 'Please mention status' }]}>
                    <Select placeholder="Please update the current status" disabled={initialValues.currentStatus == 'ALLOCATED'}>
                        {status.map((data, key) => (
                            <Option value={data.id}>{data.name}</Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item name={'expectedDate'} label={'Expected Date'} rules={[{ required: true, message: 'Please mention expected date' }]}>
                    <DatePicker format={dateFormat} />
                </Form.Item>

                <Form.Item
                    name={'remarks'}
                    label="Remarks"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter remarks',
                        },
                    ]}
                >
                    <TextArea rows={4} />
                </Form.Item>

                {['super', 'admin', 'sm', 'rm', 'tl','kec'].indexOf(user.role) === -1 ? (
                    <Alert
                        style={{ margin: '10px 0px' }}
                        message="Note"
                        description={`Inform your SM/RM to update the current status of this booking`}
                        type="info"
                    />
                ) : null}

                <Button
                    disabled={['super', 'admin', 'sm', 'rm','tl','kec'].indexOf(user.role) === -1}
                    type="primary"
                    htmlType="submit"
                    className="submit-button"
                >
                    SUBMIT
                </Button>
            </Form>
        </div>
    );
}

function CustomerProof({ city, id, booking, callback }) {
    const [form] = Form.useForm();

    const [disabled, setDisabled] = useState(false);

    const [edit, setEdit] = useState(false);

    /**
     *
     *
     * @param {*} values
     */
    const onDataCollection = (values) => {
        let params = {
            proof: {
                ...values,
            },
            document_status: 'completed',
        };

        FirebaseUtils.updateBookingGeneric(id, params).then(() => {
            message.success('Customer proofs has been submitted, Thank you');

            callback();

            setEdit(false);
        });
    };

    function onUpload(caption, attachments) {
        setDisabled(false);

        form.setFieldsValue({ [caption]: attachments });
    }

    return (
        <div className="vehicle-card card">
            {!edit ? (
                <>
                    <Title level={3}>Customer Proof</Title>

                    <ImageContainer file={booking.proof} />

                    <Button
                        type="secondary"
                        onClick={() => {
                            setEdit(!edit);
                        }}
                        className="submit-button"
                    >
                        Edit
                    </Button>
                </>
            ) : (
                <>
                    <>
                        <Title level={3}>Customer Proof</Title>

                        <p>Please upload the below requested customer details for proceeding with the booking</p>

                        <Form
                            form={form}
                            {...layout}
                            name="new-record"
                            onFinish={onDataCollection}
                            layout="vertical"
                            // validateMessages={validateMessages}
                            // initialValues={booking.proof}
                        >
                            {['Customer Photo', 'Aadhar', 'Documents'].map((caption, key) => {
                                return (
                                    <div className="elements" key={key}>
                                        <Form.Item name={caption} label={caption} rules={[{ required: true, message: 'Please attach ' + caption }]}>
                                            <FileUpload
                                                callback={(attachments) => {
                                                    onUpload(caption, attachments);
                                                }}
                                                onProgress={() => {
                                                    setDisabled(true);
                                                }}
                                            />
                                        </Form.Item>
                                    </div>
                                );
                            })}

                            <Button type="primary" htmlType="submit" className="submit-button">
                                SUBMIT
                            </Button>

                            <Button
                                type="secondary"
                                onClick={() => {
                                    setEdit(!edit);
                                }}
                                className="submit-button"
                            >
                                Cancel
                            </Button>
                        </Form>
                    </>
                </>
            )}
        </div>
    );
}

function WarrantyPreference({ city, id, booking, callback }) {
    const [form] = Form.useForm();

    const [selected, setSelected] = useState({});

    const [edit, setEdit] = useState(false);

    const [options, setOptions] = useState([]);

    useEffect(() => {
        if (booking.warranty && booking.warranty.partNo) {
            getSelected(booking.warranty.partNo);
        } else {
            getOptions();
        }

        return () => {};
    }, []);

    /**
     * Get the selected value for display
     */
    function getSelected(selected) {
        FirebaseUtils.getListingRecord(selected, 'accessories').then((result) => {
            // console.log(result.data());

            setSelected(result.data());
        });
    }

    /**
     *
     */
    function getOptions() {
        var queries = [
            {
                field: 'category',
                value: 'Extended Warranty',
            },
        ];

        FirebaseUtils.getAccessories(city, queries).then((result) => {
            // console.log(result);

            setOptions(result.accessories);
        });
    }

    /**
     *
     *
     * @param {*} values
     */
    const onDataCollection = (values) => {
        let selected = options.filter((entry) => entry.partNo === values.selected)[0];

        let data = {
            partName: selected.partName,
            price: selected.price,
            partNo: selected.partNo,
        };

        let params = {
            warranty: {
                status: 'Data Collected',
                ...data,
            },
        };
        // console.log("PARAM: ", params);
        // console.log("DATA: ", data);
        FirebaseUtils.updateBookingGeneric(id, params).then(() => {
            message.success('Updates has been recorded');

            callback();

            setEdit(false);
        });
    };

    return (
        <div className="vehicle-card card">
            {!edit ? (
                <>
                    <div>
                        <Title level={4}>Warranty Preference</Title>
                        {selected && selected.partName ? (
                            <div className="detail-element">
                                <Row span={12}>
                                    <span>Preference</span>
                                </Row>
                                <Row span={12}>
                                    <h3 style={{ margin: '10px 0px' }}>{selected.partName}</h3>
                                </Row>
                            </div>
                        ) : null}
                    </div>

                    {/* <Button type="secondary" onClick={() => {

                        getOptions();

                        setEdit(!edit);

                    }} className="submit-button">
                        Edit
                    </Button> */}
                </>
            ) : (
                <>
                    <>
                        <Title level={3}>Warranty Choice</Title>

                        <p>Please update the warranty choice for the customer</p>

                        <Form form={form} {...layout} name="new-record" onFinish={onDataCollection} layout="vertical">
                            <Form.Item name={'selected'} label={'Warranty'} rules={[{ required: true, message: 'Please select an option' }]}>
                                <Select placeholder="Please update the current status">
                                    {options.map((doc) => (
                                        <Option value={doc['id']}>{doc.partName}</Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            <Button type="primary" htmlType="submit" className="submit-button">
                                SUBMIT
                            </Button>

                            <Button
                                type="secondary"
                                onClick={() => {
                                    setEdit(!edit);
                                }}
                                className="submit-button"
                            >
                                Cancel
                            </Button>
                        </Form>
                    </>
                </>
            )}
        </div>
    );
}

function InsurancePreference({ city, id, booking, callback }) {
    const [type, setType] = useState(() => {
        if (booking.insurance && booking.insurance.type) {
            return booking.insurance.type;
        } else {
            return '';
        }
    });

    const { insurance = {} } = booking;

    const [form] = Form.useForm();

    const [edit, setEdit] = useState(false);

    useEffect(() => {
        return () => {};
    }, []);

    /**
     *
     *
     * @param {*} values
     */
    const onDataCollection = (values) => {
        let params = {
            insurance: {
                status: 'Data Collected',
                ...values,
            },
        };

        FirebaseUtils.updateBookingGeneric(id, params).then(() => {
            message.success('Updates has been recorded');

            callback();

            setEdit(false);
        });
    };

    const onTypeSelect = (event) => {
        setType(event.target.value);
    };

    return (
        <div className="vehicle-card card">
            {!edit ? (
                <>
                    <div>
                        <Title level={4}>Insurance Preference</Title>
                    </div>

                    {insurance && insurance.status === 'Data Collected' ? (
                        <>
                            <div gutter={0} className="detail-wrapper">
                                <div className="detail-element">
                                    <Row span={12}>
                                        <span>Type</span>
                                    </Row>
                                    <Row span={12}>
                                        <h3>{insurance.type}</h3>
                                    </Row>
                                </div>

                                {insurance.type === 'inhouse' ? (
                                    <>
                                        <div className="detail-element">
                                            <Row span={12}>
                                                <span>Nominee Name</span>
                                            </Row>
                                            <Row span={12}>
                                                <h3>{insurance.nominee_name}</h3>
                                            </Row>
                                        </div>

                                        <div className="detail-element">
                                            <Row span={12}>
                                                <span>Age</span>
                                            </Row>
                                            <Row span={12}>
                                                <h3>{insurance.age}</h3>
                                            </Row>
                                        </div>

                                        <div className="detail-element">
                                            <Row span={12}>
                                                <span>Relation</span>
                                            </Row>
                                            <Row span={12}>
                                                <h3>{insurance.relation}</h3>
                                            </Row>
                                        </div>

                                        <div className="detail-element">
                                            <Row span={12}>
                                                <span>Premium Amount</span>
                                            </Row>
                                            <Row span={12}>
                                                <h3>{insurance.premium_amount}</h3>
                                            </Row>
                                        </div>
                                    </>
                                ) : null}
                            </div>
                        </>
                    ) : null}

                    <Button
                        type="secondary"
                        onClick={() => {
                            // getOptions();

                            setEdit(!edit);
                        }}
                        className="submit-button"
                    >
                        Edit
                    </Button>
                </>
            ) : (
                <>
                    <>
                        <Title level={3}>Insurance Choice</Title>

                        <p>Please update the insurance choice for the customer</p>

                        <Form
                            form={form}
                            {...layout}
                            name="new-record"
                            onFinish={onDataCollection}
                            initialValues={{
                                ...insurance,
                            }}
                            layout="vertical"
                        >
                            <Form.Item
                                onChange={onTypeSelect}
                                name={'type'}
                                label={'Insurance Type'}
                                rules={[{ required: true, message: 'Please select Type' }]}
                            >
                                <Radio.Group>
                                    {['inhouse', 'direct'].map((option, key) => (
                                        <Radio key={key} value={option}>
                                            {option}
                                        </Radio>
                                    ))}
                                </Radio.Group>
                            </Form.Item>

                            {type === 'inhouse' ? (
                                <>
                                    <Form.Item name={'provider'} label={'Insurance Provider'} rules={[{ required: true, message: 'Please enter' }]}>
                                        <Input />
                                    </Form.Item>

                                    <Form.Item name={'nominee_name'} label={'Nominee Name'} rules={[{ required: true, message: 'Please enter' }]}>
                                        <Input />
                                    </Form.Item>

                                    <Form.Item name={'age'} label={'Age'} rules={[{ required: true, message: 'Please enter' }]}>
                                        <Input />
                                    </Form.Item>

                                    <Form.Item name={'relation'} label={'Relation'} rules={[{ required: true, message: 'Please enter' }]}>
                                        <Input />
                                    </Form.Item>

                                    <Form.Item name={'premium_amount'} label={'Premium Amount'} rules={[{ required: true, message: 'Please enter' }]}>
                                        <InputNumber />
                                    </Form.Item>
                                </>
                            ) : null}

                            <Button type="primary" htmlType="submit" className="submit-button">
                                SUBMIT
                            </Button>

                            <Button
                                type="secondary"
                                onClick={() => {
                                    setEdit(!edit);
                                }}
                                className="submit-button"
                            >
                                Cancel
                            </Button>
                        </Form>
                    </>
                </>
            )}
        </div>
    );
}

function ImageContainer({ caption, file }) {
    return (
        <div gutter={0} className="detail-wrapper">
            <div className="detail-element">
                <div span={12}>
                    {Object.keys(file).map((ele) => {
                        return (
                            <div className="card">
                                <Row span={12}>
                                    <span>{ele}</span>
                                </Row>

                                <ImageWrapper type={Array.isArray(file[ele]) ? 'array' : 'object'} content={file[ele]} />

                                {/* {
                                file[ele].type === "application/pdf"
                                    ?
                                    <embed src={file[ele].url} width="500" height="375"
                                        type="application/pdf" />
                                    :
                                    <Image width={200} src={file[ele].url} />
                            } */}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

// function VehicleCard({ vehicle, unblock, requests, city, callback, blockVehicle }) {
//     const { user } = useContext(GlobalContext);

//     return (
//         <div className="vehicle-item">
//             <div className="left">
//                 <Text level={1}>
//                     {vehicle['Variant']}
//                     <span>{` [ ${vehicle['Exterior Color Name']} ] `}</span>
//                 </Text>

//                 <div>{`${vehicle['Vin Number']}`}</div>

//                 <small className="status">
//                     <Badge status={badgeColor[vehicle['Stock Status']]} />

//                     {vehicle['Stock Location'] || vehicle['Stock Status']}
//                 </small>
//             </div>
//             <div className="right">
//                 {vehicle.allocated && vehicle.allocated.customer ? (
//                     <div>
//                         <Button
//                             size="small"
//                             onClick={() => {
//                                 unblock(vehicle);
//                             }}
//                             type="secondary"
//                         >
//                             Unblock
//                         </Button>

//                         <Tag icon={<CheckCircleOutlined />} color="success">
//                             Blocked
//                         </Tag>
//                     </div>
//                 ) : (
//                     <div>
//                         <Link to={`/${city}/${vehicle['Model']}/${vehicle['Variant']}/${vehicle['Exterior Color Name']}`}>Block Vehicle</Link>
//                     </div>
//                 )}
//             </div>
//         </div>
//     );
// }
